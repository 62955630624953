import ApiClient from '@/plugins/AxiosPlugin';
import AddToCartHelper from '../../myAccount/helper/add-to-cart-helper';
import LoadingDots from '@/dow-platform/components/commerce/shared/loading-dots/index.js';
export default {
  name: 'QuickAdd',
  components: {
    LoadingDots
  },
  data() {
    return {
      addMultipleItemsLink: 'quick-order.html?suppressDeliveryAddressChange=true',
      inputText: '',
      isButtonDisabled: true,
      errorMessage: false,
      inputValue: null,
      loading: false
    };
  },
  methods: {
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    enableButton() {
      this.isButtonDisabled = this.inputText.trim() === '';
    },
    submitInput: async function () {
      let inputContent = document.getElementById('quickAddInput').value;
      if (await this.isValidMaterial(inputContent)) {
        AddToCartHelper.addMaterialToCart(this.inputValue, 'quick-add');
      } else {
        this.errorMessage = true;
      }
    },
    isValidMaterial: async function (materialNum) {
      if (materialNum) {
        let isValidMaterial = false;
        this.loading = true;
        try {
          this.$store.commit('loading/setLoading', true);
          this.loading = false;
          const response = await ApiClient.get(this.$store.state.settings.quick_order + '?materialNumber=' + encodeURIComponent(materialNum));
          if (undefined === response.data?.errorMsg) {
            this.inputValue = response.data.variantOption?.code;
            isValidMaterial = true;
            this.$store.commit('loading/setLoading', false);
            this.loading = false;
          }
        } catch (error) {
          isValidMaterial = false;
          this.$store.commit('loading/setLoading', false);
          this.loading = false;
        }
        return isValidMaterial;
      }
    }
  }
};