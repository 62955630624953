import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-98815620"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "icon-container"
};
const _hoisted_2 = {
  class: "alert-body-container"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["aria-label", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CheckmarkIcon = _resolveComponent("CheckmarkIcon");
  const _component_ExclamationPoint = _resolveComponent("ExclamationPoint");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['alert-box', $options.alertColorClass])
  }, [_createElementVNode("span", _hoisted_1, [$props.icon == 'checkmark-icon' ? (_openBlock(), _createBlock(_component_CheckmarkIcon, {
    key: 0
  })) : _createCommentVNode("", true), $props.icon == 'exclamation-point-icon' ? (_openBlock(), _createBlock(_component_ExclamationPoint, {
    key: 1
  })) : _createCommentVNode("", true)]), _createElementVNode("span", _hoisted_2, [_createElementVNode("p", {
    class: "alert-message",
    innerHTML: $props.message
  }, null, 8, _hoisted_3), $props.button ? (_openBlock(), _createElementBlock("input", {
    key: 0,
    class: "alert-button",
    "aria-label": $props.buttonText,
    type: "button",
    value: $props.buttonText,
    onClick: _cache[0] || (_cache[0] = $event => $options.emitButtonClick()),
    onKeydown: _cache[1] || (_cache[1] = _withKeys($event => $options.emitButtonClick(), ["enter"]))
  }, null, 40, _hoisted_4)) : _createCommentVNode("", true)])], 2);
}