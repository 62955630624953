export default class AddressFilterHelper {
    static formatParentAddress(data) {
        const parentAddress = this.formatParentAddressWithoutSubAddresses(data)
        if (this.getAddressTypeString(data) !== 'payerAddress') {
            parentAddress.subAddresses = this.formatPairedAddress(data)
        }

        return parentAddress
    }

    static formatParentAddressWithoutSubAddresses(data) {
        const addressType = this.getAddressTypeString(data)

        return {
            companyName: data.internationalAddress?.companyName
                ? data.internationalAddress.companyName
                : data[addressType].companyName,
            formattedAddress: data.internationalAddress?.formattedAddress
                ? data.internationalAddress.formattedAddress
                : data[addressType].formattedAddress,
            addressId: +data.sapCustomerID,
            country: data[addressType].country.name,
            town: data[addressType].town,
            uid: data.uid,
            totalSubAddressCount: data.shipToCustomerCount,
            hasSubAddresses: data.hasMultipleSoldTos,
            displayed: true,
            nameCo: data[addressType].nameCo
        }
    }

    static formatSubAddress(data) {
        const addressType = this.getAddressTypeString(data)

        return {
            companyName: data[addressType].companyName,
            formattedAddress: data[addressType].formattedAddress,
            country: data[addressType].country.name,
            town: data[addressType].town,
            addressId: +data.sapCustomerID,
            uid: data.uid,
            displayed: true,
            nameCo: data.nameCo
        }
    }

    static formatPairedAddress(data) {
        const subAddresses = data.soldTos || data.shipTos
        return [this.formatSubAddress(subAddresses[0])]
    }

    static getAddressTypeString(data) {
        if (data.shipToAddress.id) {
            return 'shipToAddress'
        } else if (data.soldToAddress.id) {
            return 'soldToAddress'
        } else {
            return 'payerAddress'
        }
    }
}
