export const ExclamationPoint = {
    template: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.01 52.04" width="12.01" height="52.04">
        <g>
            <g>
                <path class="cucls-1" fill="inherit" d="M10 35H2L0 0h12zM1.5 43.04h9.01v9.01H1.5z"/>
                <path class="cucls-2" d="M8.23 33H3.78L2 2h8zM3.5 45.04h5v5h-5z"/>
            </g>
        </g>
    </svg>`
}
