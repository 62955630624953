import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "market-offers"
};
const _hoisted_2 = {
  class: "market-offers-title"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h3", _hoisted_2, _toDisplayString($options.getI18nMessage('market.offers.available')), 1), _createElementVNode("a", {
    class: "market-offers-link",
    href: $data.marketOfferURL,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.trackViewOffer && $options.trackViewOffer(...args))
  }, _toDisplayString($options.getI18nMessage('view.offer.link')), 9, _hoisted_3)]);
}