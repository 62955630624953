import ProductBox from '../../blocks/svg-elements/product-box/ProductBox.vue';
import { BundleIcon } from '../../blocks/svg-elements/bundle-icon';
import { Tooltip } from '../../blocks/output-elements/tooltip';
import { ChevronIcon } from '../../blocks/svg-elements/chevron-icon';
import { ThreeDots } from '../../blocks/svg-elements/three-dots';
import { CheckmarkIcon } from '../../blocks/svg-elements/checkmark-icon';
import DateHelper from '../../myAccount/helper/date-helper';
import AdditionalDetails from './../shared/additional-details.vue';
import EditCartItemHelper from './../helper/edit-cart-item-helper';
import NumberFormatterHelper from '../../myAccount/helper/numberFormat-helper';
import QuantityHelper from '@/dow-platform/components/commerce/myAccount/helper/quantity-helper';
import i18nHelper from '../../myAccount/helper/i18n-helper';
export default {
  name: 'CartItem',
  props: ['itemData', 'isMultiPo', 'isUnconfiguredEntry', 'isNeedRemoveEntry', 'isNeedEditEntry'],
  components: {
    ProductBox,
    Tooltip,
    ChevronIcon,
    AdditionalDetails,
    BundleIcon,
    ThreeDots,
    CheckmarkIcon
  },
  data() {
    return {
      showDetails: this.isNeedRemoveEntry || this.isNeedEditEntry,
      moveEditRemove: this.isUnconfiguredEntry || this.isNeedRemoveEntry || this.isNeedEditEntry
    };
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    geti18nDisplayValue: function (key) {
      return i18nHelper.geti18nDisplayValue(key);
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    emitRemoveItemModal(itemData) {
      this.$emit('show-remove-item-modal', {
        itemData,
        showModal: true
      });
    },
    editItem() {
      let cartItemInfo = {
        itemNo: this.itemData.sapLineItemNumber,
        materialNumber: this.itemData.materialNumber,
        isMarketOffer: this.itemData.isMarketOffer ?? false,
        isMultiPO: this.itemData.isMultiPO ?? false,
        forwardingAgent: this.itemData.forwardingAgent ?? ''
      };
      EditCartItemHelper.editCartItem(cartItemInfo);
    },
    showQuantityTooltip() {
      return this.itemData.salesUnitSapName && this.itemData.priceUnitSapName && this.itemData.salesUnitSapName !== this.itemData.priceUnitSapName;
    },
    quantityTooltipText() {
      const convertedQuantity = this.itemData.convertedQuantity;
      const priceUnit = this.itemData.priceUnitSapName;
      return QuantityHelper.getFormattedQuantity(convertedQuantity, priceUnit);
    },
    getFormattedDate: function (formattedDate) {
      let date = Date.parse(formattedDate);
      const dateObj = new Date(date);
      if (Number.isNaN(dateObj.getTime())) {
        return '';
      }
      const day = dateObj.toLocaleDateString(store.state.browser.cookies['languageCode'] || 'en-us', {
        day: '2-digit'
      });
      const month = dateObj.toLocaleDateString(store.state.browser.cookies['languageCode'] || 'en-us', {
        month: 'short'
      });
      const year = dateObj.toLocaleDateString(store.state.browser.cookies['languageCode'] || 'en-us', {
        year: 'numeric'
      });
      return `${day} ${DateHelper.capitalizeMonth(month)} ${year}`;
    },
    getFormattedNumber: function (value) {
      return NumberFormatterHelper.getFormattedNumber(value);
    }
  }
};