import FilterDefaultValueHelper from './filter-default-value-helper'

export default class DealsFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            approvalStatus: "false"
        }
    }
}
