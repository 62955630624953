import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "order-summary"
};
const _hoisted_2 = {
  class: "order-summary-title"
};
const _hoisted_3 = {
  key: 0,
  class: "order-summary-subtotal-title-empty"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 0,
  class: "order-summary-multiple-po"
};
const _hoisted_6 = {
  class: "order-summary-multiple-po-title"
};
const _hoisted_7 = {
  class: "notranslate"
};
const _hoisted_8 = {
  key: 1,
  class: "order-summary-po"
};
const _hoisted_9 = {
  class: "order-summary-po-title"
};
const _hoisted_10 = ["aria-label", "placeholder"];
const _hoisted_11 = {
  key: 2,
  class: "order-summary-pay-with-credit"
};
const _hoisted_12 = {
  key: 3,
  class: "order-summary-subtotal"
};
const _hoisted_13 = {
  class: "order-summary-subtotal-title"
};
const _hoisted_14 = {
  class: "order-summary-subtotal-value"
};
const _hoisted_15 = {
  class: "order-summary-subtotal-value-currency"
};
const _hoisted_16 = {
  key: 4,
  class: "order-summary-tax-message"
};
const _hoisted_17 = {
  key: 5,
  class: "order-summary-tax-message"
};
const _hoisted_18 = {
  class: "order-summary-checkout-info"
};
const _hoisted_19 = {
  class: "order-summary-buttons"
};
const _hoisted_20 = ["aria-label", "disabled", "value"];
const _hoisted_21 = {
  class: "empty-cart-modal-header",
  "aria-label": "Empty Cart Modal Header"
};
const _hoisted_22 = {
  class: "reset-modal-modal-text",
  "aria-label": "Empty Cart Modal Body"
};
const _hoisted_23 = ["aria-label"];
const _hoisted_24 = ["aria-label"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoadingDots = _resolveComponent("LoadingDots");
  const _component_DcCheckBox = _resolveComponent("DcCheckBox");
  const _component_DcModal = _resolveComponent("DcModal");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_LoadingDots, {
    loading: $data.loading
  }, null, 8, ["loading"]), _createElementVNode("h4", _hoisted_2, _toDisplayString($options.getLabel('order.summary.Title')), 1), !$options.hasItemsInCart() ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($options.getLabel('subtotal')), 1)) : _createCommentVNode("", true), $options.hasItemsInCart() ? (_openBlock(), _createElementBlock("div", _hoisted_4, [$data.isMultiPO ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($options.getLabel('text.search.option.poNo')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cartEntries, (entry, index) => {
    return _openBlock(), _createElementBlock("p", {
      class: "order-summary-multiple-po-item",
      key: index
    }, [_createElementVNode("span", _hoisted_7, _toDisplayString(entry.lineItemPO), 1), _createElementVNode("span", null, _toDisplayString(entry.totalPrice.formattedValue) + " " + _toDisplayString(entry.currencyIso), 1)]);
  }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString($options.getLabel('text.search.option.poNo')), 1), _createElementVNode("input", {
    maxlength: "35",
    "aria-label": $options.getLabel('checkout.payment.PONumber.enterPONumber'),
    type: "text",
    placeholder: $options.getLabel('checkout.payment.PONumber.enterPONumber')
  }, null, 8, _hoisted_10)])), $options.canPayByCreditCard() ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_DcCheckBox, {
    name: "order-summary-pay-with-credit",
    type: "checkbox",
    label: $options.getLabel('checkout.pay.by.creditcard')
  }, null, 8, ["label"])])) : _createCommentVNode("", true), !$data.isMultiPO ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("p", _hoisted_13, _toDisplayString($options.getLabel('subtotal')), 1), _createElementVNode("p", _hoisted_14, [_createTextVNode(_toDisplayString($options.subTotal()) + " ", 1), _createElementVNode("p", _hoisted_15, _toDisplayString($options.getCurrency()), 1)])])) : _createCommentVNode("", true), $data.isIndia ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString($options.getLabel('cart.order.summary.message.checkout.india')), 1)) : _createCommentVNode("", true), $data.isExchangeMessage ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString($options.getLabel('review.business.rules')), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_18, _toDisplayString($options.getLabel('cart.rightRail.des.text')), 1), _createElementVNode("div", _hoisted_19, [_createElementVNode("p", {
    class: "order-summary-buttons-remove",
    tabindex: "0",
    onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers($event => $options.toggleModal(), ["prevent"]), ["enter"])),
    onClick: _cache[1] || (_cache[1] = $event => $options.toggleModal())
  }, _toDisplayString($options.getLabel('button.empty.cart.UPPER')), 33), _createElementVNode("input", {
    class: "order-summary-buttons-checkout",
    "aria-label": $options.getLabel('button.checkout.UPPER'),
    type: "button",
    disabled: $props.isButtonDisabled,
    value: $options.getLabel('button.checkout.UPPER'),
    onClick: _cache[2] || (_cache[2] = (...args) => _ctx.submitInput && _ctx.submitInput(...args)),
    onKeydown: _cache[3] || (_cache[3] = _withKeys((...args) => _ctx.submitInput && _ctx.submitInput(...args), ["enter"]))
  }, null, 40, _hoisted_20)])])) : _createCommentVNode("", true)]), $data.showEmptyCartModal ? (_openBlock(), _createBlock(_component_DcModal, {
    key: 0,
    onModalClose: _cache[6] || (_cache[6] = $event => $options.toggleModal())
  }, {
    header: _withCtx(() => [_createElementVNode("h4", _hoisted_21, _toDisplayString($options.getLabel('button.empty.cart.UPPER')), 1)]),
    body: _withCtx(() => [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", null, _toDisplayString($props.removeMessage), 1)])]),
    footer: _withCtx(() => [_createElementVNode("button", {
      class: "empty-cart-modal-button-no",
      type: "submit",
      onClick: _cache[4] || (_cache[4] = $event => $options.toggleModal()),
      "aria-label": $options.getLabel('button.no.UPPER')
    }, _toDisplayString($options.getLabel('button.no.UPPER')), 9, _hoisted_23), _createElementVNode("button", {
      class: "empty-cart-modal-button-yes",
      type: "submit",
      onClick: _cache[5] || (_cache[5] = $event => $options.removeAll()),
      "aria-label": $options.getLabel('button.yes.UPPER')
    }, _toDisplayString($options.getLabel('button.yes.UPPER')), 9, _hoisted_24)]),
    _: 1
  })) : _createCommentVNode("", true)], 64);
}