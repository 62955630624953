import { makeUniqueId } from '@/utils';
import { ErrorMessage } from 'vee-validate';
export default {
  inheritAttrs: false,
  props: {
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    nolabel: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
    placeholderInput: {
      type: String,
      default: ''
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    },
    validateOnChange: {
      type: Boolean,
      default: true
    },
    validateOnInput: {
      type: Boolean,
      default: true
    },
    customErrorMessage: {
      type: String
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    const dcTextInput = this.$refs.dcTextInput;
    const observer = new MutationObserver(mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'errormessage') {
          let message = dcTextInput.getAttribute('errormessage');
          if (message) {
            message = this.customErrorMessage ? this.customErrorMessage : message;
          }
          let param = {
            name: this.name,
            label: this.label,
            errorMessage: message
          };
          this.$emit('error-message-change', param);
        }
      }
    });
    observer.observe(dcTextInput, {
      attributes: true
    });
  },
  components: {
    ErrorMessage
  },
  computed: {
    randomId() {
      return makeUniqueId(this.name, 6);
    }
  },
  methods: {
    onChange(data) {
      let param = {
        name: this.name,
        label: this.label,
        errorMessage: data
      };
      this.$emit('text-change', param);
    },
    onInput(event) {
      this.$emit('input', event.target.value);
    }
  }
};