export const CheckmarkIcon = {
    template: `
        <svg
            class="svg-form-checkmark"
            data-name="form-checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.7 8.18"
            width="10.7"
            height="8.18"
        >
            <path
                d="M3.78 5.67L1.26 3.15 0 4.41l2.52 2.51 1.26 1.26 1.26-1.26 5.66-5.66L9.44 0 3.78 5.67z"
            />
        </svg>
	`
}
