import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import PurchaseCartAlert from '../shared/purchase-cart-alert.vue';
import EditCartItemHelper from './../helper/edit-cart-item-helper';
export default {
  name: 'CartAlertBox',
  props: ['cartData', 'unableToComplete'],
  components: {
    PurchaseCartAlert
  },
  data() {
    return {
      cartEntries: this.cartData?.cartData.entries,
      pageReloaded: false,
      unconfiguredLineItemNumbers: this.getUnconfiguredLineItemNumbers(),
      needRemoveLineItemNumbers: this.getNeedRemoveLineItemNumbers(),
      needEditLineItemNumbers: this.getNeedEditLineItemNumbers(),
      cartItemLimit: 25
    };
  },
  created() {
    if (sessionStorage.getItem('itemRemoved') === 'true') {
      this.pageReloaded = true;
    }
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    handleDocumentClick(event) {
      const interactiveElements = new Set(['INPUT', 'BUTTON', 'SELECT', 'TEXTAREA', 'A', 'path', 'svg']);
      if (interactiveElements.has(event.target.tagName) && sessionStorage.getItem('itemRemoved') === 'true') {
        this.pageReloaded = false;
        sessionStorage.removeItem('itemRemoved');
      }
    },
    getUnconfiguredLineItemNumbers() {
      let unconfiguredEntries = this.cartData?.cartData.unConfiguredEntries;
      let unconfiguredLineItemNumbers = [];
      if (unconfiguredEntries) {
        unconfiguredEntries.forEach(entry => {
          unconfiguredLineItemNumbers.push(entry.sapLineItemNumber);
        });
      }
      return unconfiguredLineItemNumbers;
    },
    getNeedRemoveLineItemNumbers() {
      let needRemoveEntries = this.cartData?.cartData.needRemoveEntries;
      let needRemoveLineItemNumbers = [];
      if (needRemoveEntries) {
        needRemoveEntries.forEach(entry => {
          needRemoveLineItemNumbers.push(entry.sapLineItemNumber);
        });
      }
      return needRemoveLineItemNumbers;
    },
    getNeedEditLineItemNumbers() {
      let needEditEntries = this.cartData?.cartData.needEditEntries;
      let needEditLineItemNumbers = [];
      if (needEditEntries) {
        needEditEntries.forEach(entry => {
          needEditLineItemNumbers.push(entry.sapLineItemNumber);
        });
      }
      return needEditLineItemNumbers;
    },
    getUnconfiguredMessage() {
      return '<span>' + this.getI18nMessage('cart.page.item.unconfigure') + ' </span>' + this.getI18nMessage('cart.page.item.need.update');
    },
    getNeedRemoveMessage() {
      return '<span>' + this.getI18nMessage('cart.page.item.revalidation.unconfigure') + ' </span>' + this.getI18nMessage('cart.page.item.revalidation.need.update');
    },
    getEditUnconfiguredItemsButtonMessage() {
      return this.getI18nMessage('cart.page.item.edit.label.multiple') + ' (' + this.getUnconfiguredLineItemNumbers().length + ')';
    },
    getNeedEditItemsButtonMessage() {
      return this.getI18nMessage('cart.page.item.edit.label.multiple') + ' (' + this.getNeedEditLineItemNumbers().length + ')';
    },
    getHasTooManyCartItemsMessage() {
      let cartItemDif = this.cartEntries.length - this.cartItemLimit;
      return cartItemDif > 1 ? '<span>' + this.getI18nMessage('20itemWarningSingular').replaceAll('{0}', this.cartItemLimit) + '</span>' : '<span>' + this.getI18nMessage('20itemWarningPlural').replaceAll('{0}', this.cartItemLimit).replaceAll('{1}', cartItemDif) + '</span>';
    },
    editUnconfiguredItems() {
      EditCartItemHelper.editUnconfiguredCartItems();
    },
    editNeedEditItems() {
      EditCartItemHelper.editNeedEditCartItems();
    }
  }
};