import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "cart-item-options"
};
const _hoisted_2 = ["aria-label"];
const _hoisted_3 = ["aria-label"];
const _hoisted_4 = {
  class: "cart-item-header"
};
const _hoisted_5 = {
  class: "cart-item-header-icon"
};
const _hoisted_6 = {
  class: "cart-item-header-info"
};
const _hoisted_7 = {
  key: 0,
  class: "cart-item-header-info-container"
};
const _hoisted_8 = {
  class: "cart-item-header-info-container-name notranslate"
};
const _hoisted_9 = {
  key: 1,
  class: "cart-item-header-info-container-name notranslate"
};
const _hoisted_10 = {
  key: 2,
  class: "cart-item-header-info-container cart-push-right"
};
const _hoisted_11 = {
  class: "cart-item-header-info-container-number cart-push-right"
};
const _hoisted_12 = {
  class: "notranslate"
};
const _hoisted_13 = {
  key: 3,
  class: "cart-item-header-info-container-number cart-push-right"
};
const _hoisted_14 = {
  class: "notranslate"
};
const _hoisted_15 = {
  class: "cart-item-body"
};
const _hoisted_16 = {
  class: "cart-item-body-info-container"
};
const _hoisted_17 = {
  class: "unit-price"
};
const _hoisted_18 = {
  class: "cart-item-body-info-container-title"
};
const _hoisted_19 = {
  key: 0,
  class: "cart-item-body-info-container-unconfigured"
};
const _hoisted_20 = {
  key: 1,
  class: "cart-item-quantity-display"
};
const _hoisted_21 = {
  key: 0,
  class: "cart-item-body-info-container-title"
};
const _hoisted_22 = {
  key: 1,
  class: "cart-item-body-info-container-title"
};
const _hoisted_23 = {
  key: 2,
  class: "cart-item-body-info-container-unconfigured"
};
const _hoisted_24 = {
  class: "cart-item-requested-date-icon"
};
const _hoisted_25 = {
  class: "cart-item-requested-date-text"
};
const _hoisted_26 = {
  key: 4,
  class: "cart-item-requested-date-pending"
};
const _hoisted_27 = {
  class: "cart-item-body-info-container"
};
const _hoisted_28 = {
  class: "unit-price"
};
const _hoisted_29 = {
  class: "cart-item-body-info-container-title"
};
const _hoisted_30 = {
  key: 0,
  class: "cart-item-body-info-container-unconfigured"
};
const _hoisted_31 = {
  class: "cart-item-body-info-container"
};
const _hoisted_32 = {
  class: "cart-item-body-info-container-title"
};
const _hoisted_33 = {
  key: 0,
  class: "cart-item-body-info-container-unconfigured"
};
const _hoisted_34 = {
  key: 2,
  class: "cart-item-body-info-container exchange-rate"
};
const _hoisted_35 = {
  class: "cart-item-body-info-container-title"
};
const _hoisted_36 = {
  class: "cart-item-body-info-container-body"
};
const _hoisted_37 = {
  key: 0,
  class: "cart-item-body-info-container"
};
const _hoisted_38 = {
  class: "cart-item-body-info-container-title"
};
const _hoisted_39 = {
  class: "cart-item-body-info-container-body"
};
const _hoisted_40 = {
  key: 0,
  class: "cart-item-body-arrow"
};
const _hoisted_41 = ["aria-label", "title"];
const _hoisted_42 = ["aria-label", "title"];
const _hoisted_43 = ["aria-label", "value"];
const _hoisted_44 = ["aria-label", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BundleIcon = _resolveComponent("BundleIcon");
  const _component_ProductBox = _resolveComponent("ProductBox");
  const _component_Tooltip = _resolveComponent("Tooltip");
  const _component_ThreeDots = _resolveComponent("ThreeDots");
  const _component_CheckmarkIcon = _resolveComponent("CheckmarkIcon");
  const _component_ChevronIcon = _resolveComponent("ChevronIcon");
  const _component_AdditionalDetails = _resolveComponent("AdditionalDetails");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [!$data.moveEditRemove ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: "#",
    class: "cart-item-options-link",
    "aria-label": $options.getI18nMessage('button.edit.UPPER'),
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => $options.editItem(), ["prevent"])),
    onKeydown: _cache[1] || (_cache[1] = _withKeys($event => $options.editItem(), ["enter"]))
  }, _toDisplayString($options.getI18nMessage('button.edit.UPPER')), 41, _hoisted_2)) : _createCommentVNode("", true), !$data.moveEditRemove ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: "#",
    class: "cart-item-options-link",
    "aria-label": $options.getI18nMessage('remove.UPPER'),
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => $options.emitRemoveItemModal($props.itemData), ["prevent"])),
    onKeydown: _cache[3] || (_cache[3] = _withKeys(event => {
      event.preventDefault();
      event.stopPropagation();
      $options.emitRemoveItemModal($props.itemData);
    }, ["enter"]))
  }, _toDisplayString($options.getI18nMessage('remove.UPPER')), 41, _hoisted_3)) : _createCommentVNode("", true)]), _createElementVNode("div", {
    class: _normalizeClass(["cart-item", {
      'cart-item-unconfigured': $props.isUnconfiguredEntry,
      'cart-item-remove': $props.isNeedRemoveEntry || $props.isNeedEditEntry
    }])
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, [$props.itemData.isBundled ? (_openBlock(), _createBlock(_component_BundleIcon, {
    key: 0,
    class: "cart-item-header-icon-bundled"
  })) : (_openBlock(), _createBlock(_component_ProductBox, {
    key: 1
  }))]), _createElementVNode("div", _hoisted_6, [$props.itemData.myPartDescription ? (_openBlock(), _createElementBlock("span", _hoisted_7, [_createElementVNode("h3", _hoisted_8, _toDisplayString($props.itemData.myPartDescription), 1), _createVNode(_component_Tooltip, {
    notranslate: true,
    passedText: $props.itemData.salesText
  }, null, 8, ["passedText"])])) : (_openBlock(), _createElementBlock("h3", _hoisted_9, _toDisplayString($props.itemData.salesText), 1)), $props.itemData.myPartNumber ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_createElementVNode("p", _hoisted_11, [_createTextVNode(_toDisplayString($options.getI18nMessage('part.no.Title')) + " ", 1), _createElementVNode("span", _hoisted_12, _toDisplayString($props.itemData.myPartNumber), 1)]), _createVNode(_component_Tooltip, {
    notranslate: true,
    passedText: $options.getI18nMessage('material.no.Title') + $props.itemData.materialNumber
  }, null, 8, ["passedText"])])) : (_openBlock(), _createElementBlock("p", _hoisted_13, [_createTextVNode(_toDisplayString($options.getI18nMessage('material.no.Title')) + " ", 1), _createElementVNode("span", _hoisted_14, _toDisplayString($props.itemData.materialNumber), 1)]))])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", {
    class: _normalizeClass(["cart-item-body-info", $props.itemData?.shipMode ? 'shipModeCol' : ''])
  }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("p", _hoisted_18, _toDisplayString($options.getI18nMessage('availability.quantity.label')), 1)]), $props.isUnconfiguredEntry ? (_openBlock(), _createElementBlock("p", _hoisted_19, " -- ")) : (_openBlock(), _createElementBlock("span", _hoisted_20, [_createElementVNode("p", {
    class: _normalizeClass(["cart-item-body-info-container-body", {
      'cart-item-body-info-container-remove': $props.isNeedRemoveEntry || $props.isNeedEditEntry
    }])
  }, _toDisplayString($props.itemData.displayQuantity ? $props.itemData.displayQuantity : $props.itemData.quantity) + " " + _toDisplayString($props.itemData.salesUnitSapName), 3), $options.showQuantityTooltip() ? (_openBlock(), _createBlock(_component_Tooltip, {
    key: 0,
    class: "cart-item-body-info-container-tooltip",
    passedText: $options.quantityTooltipText(),
    tabindex: "0",
    "aria-label": $options.quantityTooltipText()
  }, null, 8, ["passedText", "aria-label"])) : _createCommentVNode("", true)]))]), _createElementVNode("div", {
    class: _normalizeClass(["cart-item-body-info-container cart-item-requested-date", {
      'is-non-real-time': $props.itemData.nonRealTime
    }])
  }, [$props.itemData.shipCondition == '02' || $props.itemData.shipCondition == '19' ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString($options.getI18nMessage('pickup.date.Title')), 1)) : (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString($options.getI18nMessage('requested.delivery.date')), 1)), $props.isUnconfiguredEntry ? (_openBlock(), _createElementBlock("p", _hoisted_23, " -- ")) : (_openBlock(), _createElementBlock("p", {
    key: 3,
    class: _normalizeClass(["cart-item-body-info-container-body", {
      'cart-item-body-info-container-remove': $props.isNeedRemoveEntry || $props.isNeedEditEntry
    }])
  }, [_createElementVNode("span", _hoisted_24, [$props.itemData.nonRealTime ? (_openBlock(), _createBlock(_component_ThreeDots, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_CheckmarkIcon, {
    key: 1
  }))]), _createElementVNode("span", _hoisted_25, _toDisplayString($options.getFormattedDate($props.itemData.deliveryDate)), 1)], 2)), $props.itemData.nonRealTime ? (_openBlock(), _createElementBlock("p", _hoisted_26, [_createTextVNode(_toDisplayString($options.getI18nMessage('delivery.date.pending')) + " ", 1), _createVNode(_component_Tooltip, {
    passedText: $options.getI18nMessage('cart.page.line.item.preferredDate') + '<br>' + $options.getFormattedDate($props.itemData.preferredDeliveryDate)
  }, null, 8, ["passedText"])])) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_27, [_createElementVNode("div", _hoisted_28, [_createElementVNode("p", _hoisted_29, _toDisplayString($options.getI18nMessage('cart.page.line.item.unitPrice')) + " " + _toDisplayString($props.itemData.displayPrice ? $options.getI18nMessage('cart.page.line.item.unitPrice.surcharge') : ''), 1), $props.itemData.isBundled ? (_openBlock(), _createBlock(_component_Tooltip, {
    key: 0,
    class: "cart-item-body-info-container-tooltip",
    passedText: $options.getI18nMessage('product.family.pricing.active.Title'),
    tabindex: "0",
    "aria-label": $options.getI18nMessage('product.family.pricing.active.Title')
  }, null, 8, ["passedText", "aria-label"])) : _createCommentVNode("", true)]), $props.isUnconfiguredEntry ? (_openBlock(), _createElementBlock("p", _hoisted_30, " -- ")) : (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: _normalizeClass(["cart-item-body-info-container-body", {
      'cart-item-body-info-container-remove': $props.isNeedRemoveEntry || $props.isNeedEditEntry
    }])
  }, _toDisplayString($props.itemData.displayPrice && $props.itemData.formattedDisplayPrice ? $props.itemData.formattedDisplayPrice : $props.itemData.formatUnitPrice) + " " + _toDisplayString($props.itemData.currencyIso) + " / " + _toDisplayString($props.itemData.priceUnitSapName), 3))]), _createElementVNode("div", _hoisted_31, [_createElementVNode("p", _hoisted_32, _toDisplayString($options.getI18nMessage('cart.page.line.item.amount')) + " " + _toDisplayString($props.itemData.displayPrice ? $options.getI18nMessage('cart.page.line.item.unitPrice.surcharge') : ''), 1), $props.isUnconfiguredEntry ? (_openBlock(), _createElementBlock("p", _hoisted_33, " -- ")) : (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: _normalizeClass(["cart-item-body-info-container-body", {
      'cart-item-body-info-container-remove': $props.isNeedRemoveEntry || $props.isNeedEditEntry
    }])
  }, _toDisplayString($props.itemData.totalPrice.formattedValue) + " " + _toDisplayString($props.itemData.currencyIso), 3)), $props.itemData.exchangeRate > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_34, [_createElementVNode("p", _hoisted_35, _toDisplayString($options.getI18nMessage('return.details.page.net.valueExchangerate')), 1), _createElementVNode("p", _hoisted_36, _toDisplayString($options.getFormattedNumber($props.itemData.exchangeRate)), 1)])) : _createCommentVNode("", true)]), $props.itemData?.shipMode ? (_openBlock(), _createElementBlock("div", _hoisted_37, [_createElementVNode("p", _hoisted_38, _toDisplayString($options.getI18nMessage('cart.page.line.item.modeOfTransport')), 1), _createElementVNode("p", _hoisted_39, _toDisplayString($options.geti18nDisplayValue($props.itemData.shipMode)), 1)])) : _createCommentVNode("", true)], 2), !$data.moveEditRemove ? (_openBlock(), _createElementBlock("div", _hoisted_40, [_createElementVNode("a", {
    href: "#",
    onClick: _cache[4] || (_cache[4] = _withModifiers((...args) => $options.toggleDetails && $options.toggleDetails(...args), ["prevent"])),
    class: _normalizeClass(["cart-item-trigger", {
      'is-open': $data.showDetails
    }]),
    "aria-label": $data.showDetails ? $options.getI18nMessage('collapse') : $options.getI18nMessage('expand'),
    title: $data.showDetails ? $options.getI18nMessage('collapse') : $options.getI18nMessage('expand')
  }, [_createVNode(_component_ChevronIcon)], 10, _hoisted_41)])) : _createCommentVNode("", true)]), $data.showDetails ? (_openBlock(), _createBlock(_component_AdditionalDetails, {
    key: 0,
    item: $props.itemData,
    isMultiPo: $props.isMultiPo
  }, null, 8, ["item", "isMultiPo"])) : _createCommentVNode("", true), !$data.moveEditRemove ? (_openBlock(), _createElementBlock("a", {
    key: 1,
    href: "#",
    onClick: _cache[5] || (_cache[5] = _withModifiers((...args) => $options.toggleDetails && $options.toggleDetails(...args), ["prevent"])),
    class: _normalizeClass(["cart-item-trigger-mobile", {
      'is-open': $data.showDetails
    }]),
    "aria-label": $data.showDetails ? $options.getI18nMessage('collapse') : $options.getI18nMessage('expand'),
    title: $data.showDetails ? $options.getI18nMessage('collapse') : $options.getI18nMessage('expand')
  }, [_createTextVNode(_toDisplayString($data.showDetails ? $options.getI18nMessage('hide.item.details') : $options.getI18nMessage('view.item.details')) + " ", 1), _createVNode(_component_ChevronIcon)], 10, _hoisted_42)) : _createCommentVNode("", true), $data.moveEditRemove ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(["cart-item-footer-container", {
      'cart-item-footer-container-red-border': $props.isNeedRemoveEntry,
      'cart-item-footer-container-yellow-border': $props.isUnconfiguredEntry || $props.isNeedEditEntry
    }])
  }, [$props.isUnconfiguredEntry || $props.isNeedEditEntry ? (_openBlock(), _createElementBlock("input", {
    key: 0,
    class: "cart-item-footer-container-button-1",
    "aria-label": $options.getI18nMessage('cart.page.item.edit.label'),
    type: "button",
    value: $options.getI18nMessage('cart.page.item.edit.label'),
    onClick: _cache[6] || (_cache[6] = $event => $options.editItem()),
    onKeydown: _cache[7] || (_cache[7] = _withKeys($event => $options.editItem(), ["enter"]))
  }, null, 40, _hoisted_43)) : _createCommentVNode("", true), _createElementVNode("input", {
    class: _normalizeClass({
      'cart-item-footer-container-button-1': $props.isNeedRemoveEntry,
      'cart-item-footer-container-button-2': $props.isUnconfiguredEntry || $props.isNeedEditEntry
    }),
    "aria-label": $options.getI18nMessage('remove.UPPER'),
    type: "button",
    value: $options.getI18nMessage('remove.UPPER'),
    onClick: _cache[8] || (_cache[8] = $event => $options.emitRemoveItemModal($props.itemData)),
    onKeydown: _cache[9] || (_cache[9] = _withKeys($event => $options.emitRemoveItemModal($props.itemData), ["enter"]))
  }, null, 42, _hoisted_44)], 2)) : _createCommentVNode("", true)], 2)], 64);
}