export default {
  name: 'MarketOffer',
  data() {
    return {
      marketOfferURL: 'promotion-offer.html'
    };
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    trackViewOffer: function () {
      this.$store.dispatch('trackLink', {
        name: 'View Offers',
        position: 'cart'
      });
    }
  }
};