import { ref } from 'vue';
import DcCheckBox from '@/dow-platform/components/ui/forms/DcCheckBox.vue';
import DcModal from '../../../ui/modal/DcModal.vue';
import ApiClient from '@/plugins/AxiosPlugin';
import LoadingDots from '@/dow-platform/components/commerce/shared/loading-dots/index.js';
export default {
  name: 'OrderSummary',
  emits: ['emptyCartFailure'],
  props: {
    cartData: {
      type: Array,
      required: true
    },
    removeMessage: {
      type: String,
      required: true
    },
    isButtonDisabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCheckoutDisabled: true,
      showEmptyCartModal: false,
      loading: false,
      cartEntries: this.cartData?.cartData.entries,
      isMultiPO: this.cartData?.cartData.isMultiPO,
      isIndia: this.cartData?.isIndia,
      isExchangeMessage: this.cartData?.isExchangeMessage
    };
  },
  components: {
    DcCheckBox,
    DcModal,
    LoadingDots
  },
  methods: {
    getLabel: function (key) {
      return window.Granite.I18n.get(key);
    },
    subTotal: function () {
      return this.cartData?.cartData.totalsByCurrencies[0].subtotalAmountFormatted;
    },
    getCurrency: function () {
      return this.cartData?.cartData.totalsByCurrencies[0].currency;
    },
    canPayByCreditCard: function () {
      return this.cartData?.canPayByCreditCard;
    },
    hasItemsInCart: function () {
      return typeof this.cartData === 'undefined' ? false : true;
    },
    toggleModal: function () {
      this.showEmptyCartModal = !this.showEmptyCartModal;
    },
    removeAll() {
      this.loading = true;
      ApiClient.get(this.$store.state.settings.remove_cart_servlet, {
        params: {
          removeAll: true
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        this.loading = false;
        this.updateCartCount();
        window.location.reload();
      }.bind(this)).catch(error => {
        this.loading = false;
        this.$emit('emptyCartFailure', true);
        console.error('Error', error);
      });
      console.log("Submit Empty Cart");
    },
    updateCartCount() {
      if (this.isEditMode !== 'true') {
        var purchaseFormData = new FormData();
        console.log(purchaseFormData);
        purchaseFormData.set('number', '0');
        ApiClient.post('/.dow.purchase.cart.count.json', purchaseFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$store.state.user.dccUserInfo.data.numberOfCartItems = 0;
      }
    }
  }
};