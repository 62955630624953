import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PurchaseCartAlert = _resolveComponent("PurchaseCartAlert");
  return _openBlock(), _createElementBlock(_Fragment, null, [$data.pageReloaded ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 0,
    message: $options.getI18nMessage('cart.page.message.itemRemove'),
    icon: 'checkmark-icon'
  }, null, 8, ["message"])) : _createCommentVNode("", true), $data.unconfiguredLineItemNumbers.length > 0 ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 1,
    onAlertButtonClick: _cache[0] || (_cache[0] = $event => $options.editUnconfiguredItems()),
    message: $options.getUnconfiguredMessage(),
    icon: 'exclamation-point-icon',
    button: true,
    buttonText: $options.getEditUnconfiguredItemsButtonMessage()
  }, null, 8, ["message", "buttonText"])) : _createCommentVNode("", true), $data.needRemoveLineItemNumbers.length > 0 ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 2,
    message: $options.getNeedRemoveMessage(),
    icon: 'exclamation-point-icon',
    color: 'error'
  }, null, 8, ["message"])) : _createCommentVNode("", true), $data.needEditLineItemNumbers.length > 0 ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 3,
    onAlertButtonClick: _cache[1] || (_cache[1] = $event => $options.editNeedEditItems()),
    message: $options.getNeedRemoveMessage(),
    icon: 'exclamation-point-icon',
    color: 'error',
    button: true,
    buttonText: $options.getNeedEditItemsButtonMessage()
  }, null, 8, ["message", "buttonText"])) : _createCommentVNode("", true), $props.unableToComplete ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 4,
    message: $options.getI18nMessage('deliveryDetails.page.trackDelivery.error'),
    icon: 'exclamation-point-icon',
    color: 'error'
  }, null, 8, ["message"])) : _createCommentVNode("", true), $data.cartEntries.length > $data.cartItemLimit ? (_openBlock(), _createBlock(_component_PurchaseCartAlert, {
    key: 5,
    message: $options.getHasTooManyCartItemsMessage(),
    icon: 'exclamation-point-icon',
    color: 'error'
  }, null, 8, ["message"])) : _createCommentVNode("", true)], 64);
}