import { CloseIconRed } from '../../blocks/svg-elements/close-icon-red';
export default {
  name: 'QuickOrderModal',
  props: ['modalId', 'titleLabel', 'bodyLabel', 'leftButtonLabel', 'rightButtonLabel'],
  data() {
    return {
      activeElement: null
    };
  },
  components: {
    CloseIconRed
  },
  methods: {
    removeRowOrCancel() {
      this.$emit('modalFunction', this.modalId);
    }
  }
};