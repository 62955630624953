import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "cart-item-additional"
};
const _hoisted_2 = {
  class: "cart-item-additional__content"
};
const _hoisted_3 = {
  class: "cart-item-additional__col1"
};
const _hoisted_4 = {
  key: 0,
  class: "cart-item-additional__item"
};
const _hoisted_5 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_6 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_7 = {
  key: 1,
  class: "cart-item-additional__item"
};
const _hoisted_8 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_9 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_10 = {
  key: 2,
  class: "cart-item-additional__item"
};
const _hoisted_11 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_12 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_13 = {
  key: 3,
  class: "cart-item-additional__item"
};
const _hoisted_14 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_15 = {
  class: "cart-item-additional__item-value"
};
const _hoisted_16 = ["href"];
const _hoisted_17 = {
  class: "cart-item-additional__col2"
};
const _hoisted_18 = {
  key: 0,
  class: "cart-item-additional__item"
};
const _hoisted_19 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_20 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_21 = {
  key: 0
};
const _hoisted_22 = {
  key: 1,
  class: "cart-item-additional__item"
};
const _hoisted_23 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_24 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_25 = {
  key: 2,
  class: "cart-item-additional__item"
};
const _hoisted_26 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_27 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_28 = {
  class: "cart-item-additional__item-value notranslate"
};
const _hoisted_29 = {
  key: 4,
  class: "cart-item-additional__item"
};
const _hoisted_30 = {
  class: "cart-item-additional__item-label"
};
const _hoisted_31 = {
  class: "cart-item-additional__item-value notranslate"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.item.lineItemPO && $props.isMultiPo ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString($options.getLabel('text.search.option.poNo')), 1), _createElementVNode("p", _hoisted_6, _toDisplayString($props.item.lineItemPO), 1)])) : _createCommentVNode("", true), $props.item.incoTerms ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($options.getLabel('inco.terms')), 1), _createElementVNode("p", _hoisted_9, _toDisplayString($props.item.incoTerms), 1)])) : _createCommentVNode("", true), $props.item.shippedFrom && !$props.item.isVirtualPlant ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString($options.getLabel('cart.page.line.item.shippedFrom')), 1), _createElementVNode("p", _hoisted_12, _toDisplayString($props.item.shippedFrom), 1)])) : _createCommentVNode("", true), $props.item.url && ($props.item.orderRules?.displaySiSelectRules || $props.item.orderRules?.displayBundleRules || $props.item.orderRules?.displayStandardRules || $props.item.orderRules?.displaySpecialtyRules) ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString($options.getLabel('cart.page.line.item.rulesApplied')), 1), _createElementVNode("p", _hoisted_15, [_createElementVNode("a", {
    href: $props.item.url,
    target: "_blank"
  }, _toDisplayString($options.getLabel('terms.conditions')), 9, _hoisted_16)])])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_17, [$props.item.forwardingAgent || $props.item.forwardingAgentAddress?.formattedAddress || $props.item.forwardingAgentAddress?.companyName ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("span", _hoisted_19, _toDisplayString($options.getLabel('addressBuyer.component.forwardingAgent')), 1), _createElementVNode("p", _hoisted_20, [_createTextVNode(_toDisplayString($props.item.forwardingAgent) + " - ", 1), $props.item.forwardingAgentAddress?.companyName ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString($props.item.forwardingAgentAddress?.companyName) + ", ", 1)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.item.forwardingAgentAddress?.formattedAddress), 1)])])) : _createCommentVNode("", true), $props.item.contractData?.contractNo || $props.item.contractData?.contractName ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createElementVNode("span", _hoisted_23, _toDisplayString($options.getLabel('cart.page.line.item.cantract')), 1), _createElementVNode("p", _hoisted_24, _toDisplayString($props.item.contractData?.contractNo) + " - " + _toDisplayString($props.item.contractData?.contractName), 1)])) : _createCommentVNode("", true), $props.item.customerSpec1List?.length && $props.item.customerSpec1 !== 'Batch Search Strategy Specification' ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createElementVNode("span", _hoisted_26, _toDisplayString($options.getLabel('customer.component.customer.specification.label1')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.customerSpec1List, (spec, index) => {
    return _openBlock(), _createElementBlock("p", {
      class: "cart-item-additional__item-value notranslate",
      key: index
    }, _toDisplayString(spec), 1);
  }), 128))])) : _createCommentVNode("", true), $props.item.customCharacteristics?.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 3
  }, _renderList($props.item.customCharacteristics, (color, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "cart-item-additional__item"
    }, [_createElementVNode("span", _hoisted_27, _toDisplayString($options.getLabel('dowcorning.dcc.customercharact.color.code')), 1), _createElementVNode("p", _hoisted_28, _toDisplayString(color.value), 1)]);
  }), 128)) : _createCommentVNode("", true), $props.item.endUserCustomerNumber || $props.item.endUserAddress?.companyName || $props.item.endUserAddress?.formattedAddress ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createElementVNode("span", _hoisted_30, _toDisplayString($options.getLabel('cart.page.line.item.enduser')), 1), _createElementVNode("p", _hoisted_31, _toDisplayString($props.item.endUserCustomerNumber) + " - " + _toDisplayString($props.item.endUserAddress?.companyName) + " " + _toDisplayString($props.item.endUserAddress?.formattedAddress), 1)])) : _createCommentVNode("", true)])])]);
}