import { ExclamationPoint } from '../../blocks/svg-elements/exclamation-point';
import { CheckmarkIcon } from '../../blocks/svg-elements/checkmark-icon';
export default {
  name: 'PurchaseCartAlert',
  props: {
    message: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'default'
    },
    icon: {
      type: String,
      required: false,
      default: 'none'
    },
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false
    }
  },
  components: {
    ExclamationPoint,
    CheckmarkIcon
  },
  methods: {
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    emitButtonClick() {
      this.$emit('alert-button-click');
    }
  },
  computed: {
    alertColorClass() {
      return `alert-${this.color}`;
    }
  }
};