import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "cart-page-body"
};
const _hoisted_2 = {
  class: "cart-page-left"
};
const _hoisted_3 = {
  class: "cart-page-left-alerts"
};
const _hoisted_4 = {
  class: "cart-page-left-address"
};
const _hoisted_5 = {
  class: "cart-page-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CartAlertBox = _resolveComponent("CartAlertBox");
  const _component_AddressBox = _resolveComponent("AddressBox");
  const _component_OrderSummary = _resolveComponent("OrderSummary");
  const _component_CartItem = _resolveComponent("CartItem");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_CartAlertBox, {
    cartData: $props.cartData,
    unableToComplete: $props.apiCallFailure || $data.isEmptyCartError
  }, null, 8, ["cartData", "unableToComplete"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_AddressBox, {
    "has-forwarding-agent": $options.hasForwardingAgent
  }, null, 8, ["has-forwarding-agent"])])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_OrderSummary, {
    onEmptyCartFailure: $options.emptyCartFailure,
    removeMessage: $options.getI18nMessage('cart.page.emptyCart.popup.message'),
    isButtonDisabled: $options.isCheckoutDisabled(),
    cartData: $props.cartData
  }, null, 8, ["onEmptyCartFailure", "removeMessage", "isButtonDisabled", "cartData"])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cartEntries, (cartEntry, index) => {
    return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_CartItem, {
      onShowRemoveItemModal: $event => $options.emitRemoveItemModal(cartEntry),
      itemData: cartEntry,
      isMultiPo: $data.isMultiPO,
      isUnconfiguredEntry: $options.isUnconfiguredEntry(cartEntry),
      isNeedEditEntry: $options.isNeedEditEntry(cartEntry),
      isNeedRemoveEntry: $options.isNeedRemoveEntry(cartEntry)
    }, null, 8, ["onShowRemoveItemModal", "itemData", "isMultiPo", "isUnconfiguredEntry", "isNeedEditEntry", "isNeedRemoveEntry"])]);
  }), 256))], 64);
}