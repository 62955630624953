import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "address-box"
};
const _hoisted_2 = {
  key: 0,
  class: "delivery-tab-final-destination"
};
const _hoisted_3 = {
  class: "address-box-content"
};
const _hoisted_4 = {
  key: 0,
  class: "address-details"
};
const _hoisted_5 = {
  class: "bold content-header notranslate"
};
const _hoisted_6 = {
  class: "address-box-details"
};
const _hoisted_7 = {
  class: "bold notranslate"
};
const _hoisted_8 = {
  class: "address-details-postal notranslate"
};
const _hoisted_9 = {
  key: 0,
  class: "address-details-nameco"
};
const _hoisted_10 = {
  class: "notranslate"
};
const _hoisted_11 = {
  key: 1,
  class: "company-details"
};
const _hoisted_12 = {
  class: "bold content-header notranslate"
};
const _hoisted_13 = {
  class: "bold notranslate"
};
const _hoisted_14 = {
  class: "company-details-postal notranslate"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tooltip = _resolveComponent("Tooltip");
  const _component_QuickOrderModal = _resolveComponent("QuickOrderModal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["address-box-header", {
      'hasForwardingAgent': $props.hasForwardingAgent
    }])
  }, [_createElementVNode("button", {
    class: _normalizeClass(["delivery-tab", {
      active: $data.activeTab === 'delivery'
    }]),
    onClick: _cache[0] || (_cache[0] = $event => $data.activeTab = 'delivery')
  }, [_createTextVNode(_toDisplayString($options.getI18nMessage('delivery.address.Title')) + " ", 1), $props.hasForwardingAgent ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.getI18nMessage('addressBuyer.component.finalDestination')), 1)) : _createCommentVNode("", true)], 2), _createElementVNode("button", {
    class: _normalizeClass(["company-tab", {
      active: $data.activeTab === 'company'
    }]),
    onClick: _cache[1] || (_cache[1] = $event => $data.activeTab = 'company')
  }, _toDisplayString($options.getI18nMessage('company.title')), 3), _createVNode(_component_Tooltip, {
    class: "tabs-tooltip",
    passedText: $options.getTooltipInfoMessage()
  }, null, 8, ["passedText"])], 2), _createElementVNode("div", _hoisted_3, [$data.activeTab === 'delivery' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($options.sapCustomerId), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString($options.deliveryName), 1), _createElementVNode("p", _hoisted_8, _toDisplayString($options.deliveryPostalDetails), 1), $options.coName ? (_openBlock(), _createElementBlock("p", _hoisted_9, [_createTextVNode(_toDisplayString($options.getI18nMessage('account.myItems.myPartNumbers.nameCo')) + " ", 1), _createElementVNode("span", _hoisted_10, _toDisplayString($options.coName), 1)])) : _createCommentVNode("", true)]), _createElementVNode("button", {
    class: "change-address",
    onClick: _cache[2] || (_cache[2] = $event => $options.openTruckModal())
  }, _toDisplayString($options.getI18nMessage('change.Title')), 1)])) : _createCommentVNode("", true), $data.activeTab === 'company' ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("p", _hoisted_12, _toDisplayString($options.companyId), 1), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_13, _toDisplayString($options.companyName), 1), _createElementVNode("p", _hoisted_14, _toDisplayString($options.companyPostalDetails), 1)])])) : _createCommentVNode("", true)]), _createVNode(_component_QuickOrderModal, {
    modalId: $data.confirmAddressChangeModalId,
    titleLabel: $options.getI18nMessage('changeDeliveryAddress.cart.hasMaterials.changg.popup.title'),
    bodyLabel: $options.getI18nMessage('select.new.delivery.Sentence'),
    leftButtonLabel: $options.getI18nMessage('button.keep.delivery.addr.UPPER'),
    rightButtonLabel: $options.getI18nMessage('use.new.delivery.addr.UPPER'),
    onCloseModal: _cache[3] || (_cache[3] = $event => $options.closeModal('#confirmAddressChange'))
  }, null, 8, ["modalId", "titleLabel", "bodyLabel", "leftButtonLabel", "rightButtonLabel"])]);
}