import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate'
import { required, min, url, confirmed, max, max_value, min_value } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

export default (app) => {
    defineRule('required', required)
    defineRule('email', validateEmail)
    defineRule('min', min)
    defineRule('max_value', max_value)
    defineRule('min_value', min_value)
    defineRule('max', max)
    defineRule('url', url)
    defineRule('confirmed', confirmed)
    defineRule('confirmedPass', confirmedPass)
    defineRule('validatePass', validatePass)
    defineRule('validatePhone', validatePhone)
    defineRule('maxLength', max)
    defineRule('validateNonBlankEmail', validateNonBlankEmail)
    defineRule('validateDowEmail', validateDowEmail)
    defineRule('validateMaxLength', (value, [length]) => {
        if (!value) {
            return true
        } else {
            return (
                value.length <= length ||
                window.Granite.I18n.get('validation.field.max.length', [length])
            )
        }
    })
    defineRule('validateEmail', validateEmail)
    defineRule('betweenValues', (value, [minValue, maxValue]) => {
        if (isNaN(value)) {
            return window.Granite.I18n.get(
                'availability.quantity.error.typeMismatch'
            )
        } else {
            const numericValue = parseFloat(value)
            return (
                (numericValue <= maxValue && numericValue >= minValue) ||
                window.Granite.I18n.get('min.max.filter.text', [
                    minValue,
                    maxValue
                ])
            )
        }
    })

    configure({
        generateMessage: localize('en', {
            messages: {
                required: window.Granite.I18n.get('field.required.Sentence'),
                email: window.Granite.I18n.get('enter.valid.email.Sentence'),
                validateEmail: window.Granite.I18n.get('enter.valid.email.Sentence'),
                min: '{field} must be a minimum of 0:{min} characters',
                max: '{field} must be less than 0:{max} characters',
                min_value:  window.Granite.I18n.get('validation.field.min.value.length'),
                max_value:  window.Granite.I18n.get('validation.field.max.value.length'),
                url: '{field} must be a valid URL',
                confirmed: '{field} do not match',
                confirmedPass: window.Granite.I18n.get('validation.checkPwd.equals'),
                validatePass: window.Granite.I18n.get('updatePwd.pwd.invalid'),
                validatePhone: window.Granite.I18n.get('field.validatePhone.Sentence'),
                maxLength: window.Granite.I18n.get('validation.field.max.length'),
                validateNonBlankEmail: window.Granite.I18n.get('enter.valid.email.Sentence'),
                validateDowEmail: window.Granite.I18n.get('profile.userform.data.invalid.general'),
                validateMaxLength: window.Granite.I18n.get('validation.field.max.length')
            }
        })
    })

    function validatePass(password) {
        if (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/.test(password)) {
            return true
        }
        return false
    }

    function confirmedPass(password, confirmPassword) {
        return password == confirmPassword
    }

    function validatePhone(phoneNumber) {
        if (/^[\d- \(\)]*$/.test(phoneNumber)) {
            return true
        }
        return false
    }

    app.component('DcForm', Form)
    app.component('VeeField', Field)
    app.component('VeeErrorMessage', ErrorMessage)
}

export function validateNonBlankEmail(email) {
    if (!email || !email.length) {
        return false;
    }
    return (/^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i).test(email)
}

export function validateEmail(email) {
    if (!email || !email.length) {
        return true;
    }
    return (/^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i).test(email)
}

export function validateDowEmail(email) {
    const dowEmail = "@dow.com";
    return !email.includes(dowEmail);
}

export function validatePhone(phoneNumber) {
    if (/^[\d- \(\)]*$/.test(phoneNumber)) {
        return true
    }
    return false
}

export { Field, ErrorMessage, Form, min, max, max_value, min_value, required }
