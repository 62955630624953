import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
};
const _hoisted_3 = {
  class: "modal-content"
};
const _hoisted_4 = {
  class: "modal-header warning"
};
const _hoisted_5 = {
  class: "modal-title",
  id: "confirmRemoveLabel"
};
const _hoisted_6 = {
  class: "modal-body"
};
const _hoisted_7 = {
  class: "modal-footer warning-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CloseIconRed = _resolveComponent("CloseIconRed");
  return _openBlock(), _createElementBlock("div", {
    class: "modal fade",
    "data-dccmodal": "modal",
    id: $props.modalId,
    role: "dialog",
    "aria-labelledby": "",
    "data-backdrop": "static"
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h4", _hoisted_5, _toDisplayString($props.titleLabel), 1), _createElementVNode("button", {
    class: "close-icon",
    type: "button",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('closeModal'))
  }, [_createElementVNode("span", null, [_createVNode(_component_CloseIconRed)])])]), _createElementVNode("div", _hoisted_6, _toDisplayString($props.bodyLabel), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("button", {
    type: "button",
    "data-dismiss": "modal",
    class: "button button-cancel cancel",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('closeModal'))
  }, _toDisplayString($props.leftButtonLabel), 1), _createElementVNode("button", {
    type: "button",
    "data-dismiss": "modal",
    class: "button button-add",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.removeRowOrCancel && $options.removeRowOrCancel(...args))
  }, _toDisplayString($props.rightButtonLabel), 1)])])])], 8, _hoisted_1);
}