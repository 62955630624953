import ApiClient from '@/plugins/AxiosPlugin'
import { UserHelper } from '@/helpers/user-helper'

const { state } = require('./page')
const MAX_TDS_ITERATIONS = 2000
const PRODUCT_TAB_VIEW = 'tabview'
const SAMPLECART_ADD_EVENT = 'samplecart:add'
var currentSampleRequest = null;
var currentTradeRequest = null;

export const AJAX_URL = {
    industry_submit: '/.dow.lead.score.submarket.json',
    markets_load: '/.dow.lead.score.market.json',
    submarket_submit: '/.dow.lead.score.application.json',
    leadscore_submit: '/.dow.lead.score.json',
    leadscore_general: '/.dow.lead.score.general.json',
    get_product_categories: '/.dow.product.categories.servlet.json',
    get_tds: '/.dow.tds.json',
    get_sds: '/.dow.sds.doc.json',
    get_rdsdoc: '/.dow.rds.doc.json',
    get_fdsdoc: '/.dow.fds.doc.json',
    trade_products: '/.dow.trade.products.servlet'
}

export default {
    namespaced: true,
    state: {
        pdpData: { buyingSampleOptions: null, tradeProducts: null },
        sdsData: {},
        fdsData: {},
        clpData: {},
        isSDSLoading: false,
        isTDSLoading: false,
        isRDSLoading: false,
        isCLPLoading: false,
        technicalDataSheets: [],
        rdsDocs: [],
        fdsDocs: [],
        isFDSLoading: false,
        sampleData: {},
        samplesLoading: true,
        tradeProductsLoading: true,
        showPropertiesRightRail: true,
        isOptimisedJs: window.isOptimizePdpJs,
        isOptimisedHtml: window.isOptimizePdpHtml,
        productsToCompare: []
    },
    mutations: {
        setShowPropertiesRR: function (state, payload) {
            state.showPropertiesRightRail = payload
        },
        setProductsToCompare: function (state, payload) {
            state.productsToCompare = payload
        },
        setPdpData: function (state, payload) {
            state.pdpData = payload
        },
        setTradeProducts: function (state, payload) {
            state.pdpData.tradeProducts = payload
        },
        setFDSDocs: function (state, payload) {
            state.fdsDocs = payload
        },
        setProductTechnologyUrl: function (state, payload) {
            var url = ''

            if (state.pdpData.productTechName) {
                url =
                    '#t=Products&sort=relevancy&f:@dow_producttechnology_hierarchy=[' +
                    encodeURIComponent(state.pdpData.productTechName) +
                    ']'
            }

            state.pdpData.productTechnologyUrl = url
        },
        setProductTechnology: function (state, payload) {
            var productTechnology = {}

            var productTechnologies = payload.values

            if (productTechnologies) {
                productTechnology['value'] = productTechnologies[0].value // Only need the first one
            }

            state.pdpData.productTechnology = productTechnology
        },
        setProductCategoryCount: function (state, payload) {
            state.pdpData.productCategoryCount = payload
        },
        setTopTechnicalContent: function (state, payload) {
            var contentTypes = []

            payload.values.forEach(function (type) {
                if (type.value.includes('|||')) {
                    var splitTypes = type.value.split('|||')
                    splitTypes.forEach(function (splitType) {
                        var newType = {
                            lookupValue: splitType,
                            numberOfResults: type.numberOfResults,
                            value: splitType
                        }
                        contentTypes.push(newType)
                    })
                } else {
                    contentTypes.push(type)
                }
            })

            contentTypes.forEach(function (contentType) {
                contentType['count'] = contentType.numberOfResults
                contentType['link'] =
                    '#f:@dow_prelatedproduct=[' +
                    state.pdpData.productCode +
                    ']&t=Support&sort=relevancy&f:@dow_contenttype_hierarchy=[' +
                    contentType.value +
                    ']'
            })

            this.commit('pdp/setTechnicalContentSummary', payload)

            contentTypes = contentTypes.sort(function (contentA, contentB) {
                if (contentA.count > contentB.count) return -1
                if (contentA.count < contentB.count) return 1
                if (contentA.value > contentB.value) return 1
                if (contentA.value < contentB.value) return -1
            })

            state.pdpData.topTechnicalContent = contentTypes.splice(0, 3)
        },
        setTechnicalContentSummary: function (state, payload) {
            var general = {}

            var total = 0
            general = payload.values

            general.forEach(function (contentType) {
                if (contentType.value.includes('|||')) {
                    var splitTypes = contentType.value.split('|||')
                    splitTypes.forEach(function (splitType) {
                        total += contentType.numberOfResults
                    })
                } else {
                    total += contentType.numberOfResults
                }
            })

            general.total = total
            general.current_link =
                '#f:@dow_prelatedproduct=[' + state.pdpData.productCode + ']&t=Support&sort=relevancy'

            if (!!general) {
                state.pdpData.technicalContentSummary = {
                    count: general.total,
                    link: general.current_link
                }
            }
        },
        setBuyingSampleOptions: function (state, payload) {
            state.pdpData.buyingSampleOptions = payload
        },
        setSamplesLoading: function (state, payload) {
            state.samplesLoading = payload
        },
        setTradeProductsLoading: function (state, payload) {
            state.tradeProductsLoading = payload
        }
    },
    getters: {
        getPropertiesData: function (state) {
            return getPropertiesData(state)
        },
        getOverviewData: function (state) {
            return getOverviewData(state)
        },
        getTechnicalContentData: function (state) {
            return getTechnicalContentData(state)
        },
        getProductCategories: function (state) {
            return getProductCategories(state)
        },
        getProductTechnology: function (state) {
            return state.pdpData.productTechnology
        },
        getProductCategoryCount: function (state) {
            return getProductCategoryCount(state)
        },
        getProductTechnologyUrl: function (state) {
            return state.pdpData.productTechnologyUrl
        },
        getProductCode: function (state) {
            return state.pdpData.productCode
        },
        getProductName: function (state) {
            return state.pdpData.title
        },
        getTopTechnicalContent: function (state) {
            return state.pdpData.topTechnicalContent
        },
        getTechnicalContentSummary: function (state) {
            return state.pdpData.technicalContentSummary
        },
        getBuyingSampleOptions: function (state) {
            return state.pdpData.buyingSampleOptions
        },
        getSampleOptions: function (state) {
            return state.pdpData.buyingSampleOptions
        },
        getTradeProducts: function (state) {
            return state.pdpData.tradeProducts
        },
        getSamplesLoading: function (state) {
            return state.samplesLoading
        },
        getTradeProductsLoading: function (state) {
            return state.tradeProductsLoading
        }
    },
    actions: {
        getSDSData: getSDSData,
        getTechnicalDataSheets: getTechnicalDataSheets,
        getRDSDocs: getRDSDocs,
        getFDSData: getFDSData,
        getCLPData: getCLPData,
        fetchProductCategoryCount: function (state, params) {
            fetchProductCategoryCount(this, params)
        },
        fetchAllTechnicalContent: function (state, params) {
            fetchAllTechnicalContent(state, params)
        },
        fetchBuyingSampleOptions: function (state, params) {
            fetchBuyingSampleOptions(this, params)
        },
        fetchTradeProducts: function (state, params) {
            fetchTradeProducts(this, params)
        },
        fetchProductTechnology: function (state, params) {
            fetchProductTechnology(this, params)
        },
        clearSampleOptions: function (state) {
            clearSampleOptions(this)
        },
        addToCartTracking: async function (context, { step, products, cart }) {
            const userObj = UserHelper.getUserData();
            var shipToIdData = userObj && userObj.selectedShipToId !== undefined ? userObj.selectedShipToId : "Undefined";
            var soldToIdData = userObj && userObj.selectedSoldToId !== undefined ? userObj.selectedSoldToId : "Undefined";
            var companyName = userObj && userObj.defaultDeliveryAddress !== undefined ? userObj.defaultDeliveryAddress.name : "Undefined";

            
            await store.dispatch('product/fetchProductAttributes', {
                materialCodes: products.map((item) => item.gmid || item.SKU).join(','),
                fields: 'businesses,businessgroups,categories,marketingStatus'
            })

            for (const product of products) {
                let productAttribute = context.rootState.product.productAttributes.find(pa => product.SKU === pa.materialCode || pa.productCode);
				let marketingStatus = productAttribute?.marketingStatus ?? '';
                product.productCategories =
                    context.rootGetters['product/getCategories'][product.SKU] || []
                product.business =
                    context.rootGetters['product/getBusinesses'][product.SKU] || []
                product.businessGroup =
                    context.rootGetters['product/getBusinessGroups'][product.SKU] || []
                product.marketingStatus = marketingStatus
            }

            //push event with the path referencing the pseudo-component
            window.adobeDataLayer.push({
                event: 'cart:add',
                productListItems: products,
                cart: cart,
                cartStep: step,
                cartId: sessionStorage.getItem('cartGuid'),
                user: {
                    shipTo: (shipToIdData || "Undefined") + "|" + (companyName|| "Undefined"),
                    soldTo: (soldToIdData || "Undefined") + "|" + (companyName|| "Undefined")
                  },
            })
        },
        cancelCartTracking: async function (context, { step, products, cart }) {
            const userObj = UserHelper.getUserData();
            var shipToIdData = userObj && userObj.selectedShipToId !== undefined ? userObj.selectedShipToId : "Undefined";
            var soldToIdData = userObj && userObj.selectedSoldToId !== undefined ? userObj.selectedSoldToId : "Undefined";
            var companyName = userObj && userObj.defaultDeliveryAddress !== undefined ? userObj.defaultDeliveryAddress.name : "Undefined";

            await store.dispatch('product/fetchProductAttributes', {
                materialCodes: products.map((item) => item.gmid || item.SKU).join(','),
                fields: 'businesses,businessgroups,categories'
            })

            for (const product of products) {
                product.productCategories =
                    context.rootGetters['product/getCategories'][product.SKU] || []
                product.business =
                    context.rootGetters['product/getBusinesses'][product.SKU] || []
                product.businessGroup =
                    context.rootGetters['product/getBusinessGroups'][product.SKU] || []
            }

            //push event with the path referencing the pseudo-component
            window.adobeDataLayer.push({
                event: 'cart:cancel',
                productListItems: products,
                cart: cart,
                cartStep: step,
                cartId: sessionStorage.getItem('cartGuid'),
                user: {
                    shipTo: (shipToIdData || "Undefined") + "|" + (companyName|| "Undefined"),
                    soldTo: (soldToIdData || "Undefined") + "|" + (companyName|| "Undefined")
                  },
            })
        },
        commerceAddToCartTracking: async function (context, { step, products, ship, date, cart }) {
            await store.dispatch('product/fetchProductAttributes', {
                materialCodes: products.map((item) => item.gmid || item.SKU).join(','),
                fields: 'businesses,businessgroups,categories'
            })
            for (const product of products) {
                product.productCategories =
                    context.rootGetters['product/getCategories'][product.SKU]
                product.business =
                    context.rootGetters['product/getBusinesses'][product.SKU]
                product.businessGroup =
                    context.rootGetters['product/getBusinessGroups'][product.SKU ]
                product.marketingStatus = context.rootGetters['product/getMarketingStatus'][product.SKU]
            }
            window.adobeDataLayer.push({
                event: 'commerce.productListAdds',
                cart: cart,
                productListItems: products,
                cartId: sessionStorage.getItem('cartGuid'),
                eventInfo: {
                    step: step
                },
                ship: ship || "Undefined",
                date: date || "Undefined"
            })
        },
        cartOpenTracking: function (context) {
            window.adobeDataLayer.push({
                event: 'cart:open',
                cartId: sessionStorage.getItem('cartGuid'),
                firstCartTimeStamp: Math.floor(Date.now()/1000)
            })
        },
        addToSampleCartTracking: function (
            context,
            { step, products, sample, codeType }
        ) {
            window.adobeDataLayer.push({
                event: 'samplecart:add',
                productListItems: products,
                sample: sample,
                codeType: codeType,
                eventInfo: {
                    step: step
                }
            })
        },
        [PRODUCT_TAB_VIEW]: { root: true, handler() {} },
        [SAMPLECART_ADD_EVENT]: { root: true, handler() {}}
    }
}

export function getComponentPath(cmpId) {
    return `component.${cmpId}`
}

export function getRandomComponentName(prefixName = '') {
    var random = Math.random() * 1000000000
    random = Math.floor(random)
    var compName = prefixName + random
    return compName
}

function getPropertiesData(state) {
    return {
        propertySets: state.pdpData.propertySets,
        similarProducts: state.pdpData.similarProducts
    }
}

function getOverviewData(state) {
    return {
        title: state.pdpData.title,
        description: state.pdpData.description,
        uses: state.pdpData.uses,
        benefits: state.pdpData.benefits,
        badges: state.pdpData.badges,
        relatedProducts: state.pdpData.relatedProducts,
        similarProducts: state.pdpData.similarProducts,
        mappedProductOGImagePathUrl: state.pdpData.pdpOGImagePath,
        mappedProductGroupImageUrl: state.pdpData.pdpBannerImage,
        mappedProductGroupImageAltText:
            state.pdpData.mappedProductGroupImageAltText,
        showPreDiscontinueMsg: state.pdpData.showPreDiscontinueMsg,
        showPostDiscontinueMsg: state.pdpData.showPostDiscontinueMsg,
        discontinueDate: state.pdpData.discontinueDate,
        discontinueRegionCtry: state.pdpData.discontinueRegionCtry
    }
}

function getTechnicalContentData(state) {
    return {
        tradeProducts: state.pdpData.tradeProducts,
        productCode: state.pdpData.productCode,
        disableSaleSpec: state.pdpData.disableSaleSpec,
        websiteLanguages: state.pdpData.websiteLanguages,
        chemicalEquivalency: state.pdpData.chemicalEquivalency,
        equivDocumentTitle: state.pdpData.equivDocumentTitle,
        downloadCELink: state.pdpData.downloadCELink,
        equivDocumentLabel: state.pdpData.equivDocumentLabel,
        contactUsLink: state.pdpData.contactUsLink,
        hasFoodContact: state.pdpData.hasFoodContact,
        hasRelatedResources: state.pdpData.hasRelatedResources
    }
}

function getProductCategories(state) {
    return state.pdpData.categories
}

function getSDSData({ state, rootState }, payload) {
    var selectedTradeProduct = payload
    if (selectedTradeProduct && selectedTradeProduct != '-1') {
        state.isSDSLoading = true
        var sdsURL =
            rootState.settings.get_sds +
            '?tradeProduct=' +
            selectedTradeProduct +
            '&product=' +
            state.pdpData.productCode
        ApiClient.get(sdsURL)
            .then(function (response) {
                state.sdsData = response.data.sdsData
                state.isSDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting SDS Data')
                state.isSDSLoading = false
            })
    }
}

function getTechnicalDataSheets({ state, rootState }, payload) {
    var productCode = payload
    if (productCode) {
        state.isTDSLoading = true
        ApiClient.get(rootState.settings.get_tds, {
            params: {
                productId: productCode
            }
        })
            .then(function (response) {
                state.technicalDataSheets = []
                for (
                    var i = 0;
                    i < response.data.length && i <= MAX_TDS_ITERATIONS;
                    i++
                ) {
                    var tds = response.data[i]
                    state.technicalDataSheets.push({
                        title: tds.title ? tds.title : '',
                        pathLink: tds.pathLink
                            ? 'doc-viewer-blank.html?docPath=' +
                              tds.pathLink +
                              '&useRequestPath=true'
                            : '',
                        languageCode: tds.languageCode ? tds.languageCode : '',
                        displayLanguage: tds.displayLanguage
                            ? tds.displayLanguage
                            : '',
                        isChecked: false
                    })
                }
                state.isTDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting TDS Data')
                state.isTDSLoading = false
            })
    } else {
        console.log(
            'Error getting technical data sheet. No product code provided'
        )
    }
}

function getRDSDocs({ state, rootState }, payload) {
    var tradeProduct = payload
    if (state.pdpData.productCode && tradeProduct) {
        state.isRDSLoading = true
        ApiClient.get(rootState.settings.get_rdsdoc, {
            params: {
                tradeProduct: tradeProduct,
                product: state.pdpData.productCode
            }
        })
            .then(function (response) {
                state.rdsDocs = []
                var res = response.data.rdsData
                if (res && res.materialDataSheets) {
                    var rdsDataSheet = res.materialDataSheets[0]
                    if (rdsDataSheet && rdsDataSheet.countries) {
                        var rdsCountry = rdsDataSheet.countries[0]
                        if (rdsCountry) {
                            state.rdsDocs = rdsCountry.documents
                        }
                    }
                }
                state.isRDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting TDS Data')
                state.isRDSLoading = false
            })
    }
}

function getFDSData({ state, rootState }, payload) {
    var selectedTradeProduct = payload
    if (selectedTradeProduct && selectedTradeProduct != '-1') {
        state.isFDSLoading = true
        var fdsURL =
            rootState.settings.get_fdsdoc +
            '?tradeProduct=' +
            selectedTradeProduct +
            '&product=' +
            state.pdpData.productCode
        ApiClient.get(fdsURL)
            .then(function (response) {
                state.fdsData = response.data.fdsData
                state.isFDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting FDS Data', error)
                state.isFDSLoading = false
            })
    }
}

function getCLPData({ state, rootState }, payload) {
    var selectedTradeProduct = payload.tradeProduct

    if (selectedTradeProduct && selectedTradeProduct != '-1') {
        state.isCLPLoading = true
        var clpURL =
            rootState.settings.get_clp +
            '?tradeProduct=' +
            selectedTradeProduct +
            '&region=' +
            payload.region
        return ApiClient.get(clpURL)
            .then(function (response) {
                state.clpData = response.data
                state.isCLPLoading = false
            })
            .catch(function (error) {
                console.log('Error getting CLP Data')
                state.isCLPLoading = false
            })
    }
}

function getProductCategoryCount(state) {
    return state.pdpData.productCategoryCount
}

function fetchProductCategoryCount(context, params) {
    var existingData = context.getters['pdp/getProductCategoryCount']
    var productTechnologyName = context.getters['pdp/getProductTechnology']

    if (existingData != null) {
        // Data already exists
    } else {
        var totalCountsTemplate = {
            __additional: { q: '*', tab: 'all' },
            category: 'products',
            productType: encodeURIComponent(
                context.state.pdp.pdpData.productTechName
            )
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
        }

        var url = context.state.settings.pdp_search_servlet + '?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))

        $.ajax({
            type: 'GET',
            url: url + encoded,
            cache: false,
            dataType: context.state.settings.SP_DATATYPE,
            success: function (data) {
                var jsonResponse =
                    typeof data == 'object' ? data : JSON.parse(data)
                context.commit('pdp/setProductTechnologyUrl', jsonResponse)

                var count = (function () {
                    var result = 0
                    if (jsonResponse.values.length > 0) {
                        for (var i = 0; i < jsonResponse.values.length; i++) {
                            if (
                                jsonResponse.values[i].value ==
                                context.state.pdp.pdpData.productTechName
                            ) {
                                result += jsonResponse.values[i].numberOfResults
                            }
                        }
                    }
                    return result
                })()

                context.commit('pdp/setProductCategoryCount', count)
            }.bind(this),
            error: function (res) {
                // Error
            }
        })
    }
}

function fetchAllTechnicalContent(context, params) {
    var existingData = context.getters['pdp/getTopTechnicalContent']

    if (existingData?.length > 0) {
        // Data already exists
    } else {
        var technicalContentTemplate = {
            __additional: { q: params.productCode, tab: 'support' }
        }

        if (params.type != null) {
            technicalContentTemplate['type'] = params.type
        }

        var url = context.rootState.settings.pdp_search_servlet + '?searchObj=' 
        var encoded = encodeURIComponent(
            JSON.stringify(technicalContentTemplate)
        )

        $.ajax({
            type: 'GET',
            url: url + encoded,
            cache: false,
            // dataType: context.rootState.settings.SP_DATATYPE,
            success: function (data) {
                var jsonResponse =
                    typeof data == 'object' ? data : JSON.parse(data)
                context.commit('setTopTechnicalContent', jsonResponse)
            }.bind(this),
            error: function (res) {
                // Error
            }
        })
    }
}

function fetchProductTechnology(context, params) {
    var existingData = context.getters['pdp/getProductTechnology']

    if (existingData != null) {
        // Data already exists
    } else {
        var productTechnologyTemplate = {
            __additional: { q: params.productCode, tab: 'products' }
        }

        if (params.type != null) {
            productTechnologyTemplate['type'] = params.type
            fetchProductCategoryCount(context, {
                type: params.type
            })
        }
    }
}

function fetchBuyingSampleOptions(context, productCode) {
    context.commit('pdp/setBuyingSampleOptions', null)
    context.commit('pdp/setSamplesLoading', true)
    if(currentSampleRequest != null) {
        currentSampleRequest.abort();
        currentSampleRequest = null;
    }
    
    currentSampleRequest = $.ajax({
        type: 'GET',
        url:
            context.state.settings.buying_sample_options +
            '.' +
            productCode +
            '.json',
        cache: false,
        dataType: 'json',
        success: function (data) {
            var jsonResponse =
                typeof data == 'object' ? data : JSON.parse(data)
            context.commit('pdp/setBuyingSampleOptions', jsonResponse)
            context.commit('pdp/setSamplesLoading', false)
            currentSampleRequest = null
        }.bind(this),
        error: function (res) {
            // Error
            if(res.statusText === 'abort' || res.readyState === 0) {
                currentSampleRequest = null
            } else {
                context.commit('pdp/setSamplesLoading', false)                
                currentSampleRequest = null
            }
        }
    })
}

function fetchTradeProducts(context, productCode) {
    context.commit('pdp/setTradeProducts', null)
    context.commit('pdp/setTradeProductsLoading', true)
    if(currentTradeRequest != null) {
        currentTradeRequest.abort();
        currentTradeRequest = null;
    }

    currentTradeRequest = $.ajax({
        type: 'GET',
        url:
            context.state.settings.trade_products +
            '.' +
            productCode +
            '.json',
        cache: false,
        dataType: context.state.settings.SP_DATATYPE,
        success: function (data) {
            var jsonResponse =
                typeof data == 'object' ? data : JSON.parse(data)
            context.commit('pdp/setTradeProducts', jsonResponse)
            context.commit('pdp/setTradeProductsLoading', false)
            currentTradeRequest = null
        }.bind(this),
        error: function (res) {
            if(res.statusText === 'abort' || res.readyState === 0) {
                currentTradeRequest = null
            } else {
                console.error('Error fetching the trade products')
                context.commit('pdp/setTradeProductsLoading', false)
                currentTradeRequest = null
            }
        }
    })
}

function clearSampleOptions(context) {
    context.commit('pdp/setBuyingSampleOptions', null)
    context.commit('pdp/setTradeProducts', null)
}
