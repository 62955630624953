import store from '@/store'
import ApiClient from '@/plugins/AxiosPlugin'
export default class AddToCartHelper {
    static store = store

    static addToCartSuccessHandler(data, currentTab) {
        if (data.errorMessage) {
            this.errorMessage = data.errorMessage
            this.error = true
        } else {
            if (data.darwerURL) {
                store.state.user.dccUserInfo.numberOfCartItems =
                    data.totalUnitCount //TODO we might not need it

                if (data.materialList) {
                    var materialList = []
                    data.materialList.forEach(function (value) {
                        materialList.push({
                            code: value.code,
                            itemNo: value.itemNo,
                            isMarketOffer: false,
                            isMultiPO: data.isMultiPO
                        })
                    })
                    localStorage.setItem(
                        'materialList',
                        JSON.stringify(materialList)
                    )

                    AddToCartHelper.addToCartTracking(materialList, currentTab)
                }
                location.href = data.darwerURL
            }
        }
    }

    static addToCart(codeOptions, currentTab) {
        console.log("codeOptions' length is:" + codeOptions.length)
        if (codeOptions.length > 0) {
            const url = '/dccstorefront/dcc/en/cart/addMultiItems'
            var data = JSON.stringify({
                codeOption: codeOptions
            })

            ApiClient.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    AddToCartHelper.addToCartSuccessHandler(res.data,currentTab)
                })
                .catch(function (res) {
                    console.log(res.data + ' codeOptions:' + codeOptions)
                })
        }
    }

    static addToCartTracking(materialList, currentTab) {
        var items = store.state.myAccountItems[currentTab]?.items ? store.state.myAccountItems[currentTab].items : [] 
        var productName = store.state.product.productAttributes?.[0]?.productName

        var products = materialList.map((value) => {
            var item
            if (items)
                item = items.find((x) => x.dowMaterialNumber === value.code)

            let addMethod;
            switch(currentTab) {
                case 'favoriteBuyingOptions': addMethod = 'Favorite Buying Options'; break;
                case 'myPartNumbers': addMethod = 'My Part Numbers'; break;
                case 'previousPurchases': addMethod = 'Previous Purchases'; break;
                case 'quick-add' : addMethod = 'Quick Add'; break;
                default: addMethod = 'unavailable'; break;
            }

            return {
                SKU: value.code || 'unavailable',
                gmid: value.code || 'unavailable',
                name: item && item.dowMaterialName ? item.dowMaterialName : (productName ? productName : '' || 'unavailable'),
                productAddMethod: addMethod
            }
        })

        if (products.length > 0)
            store.dispatch('assets/addToCartTracking', {
                step: 'step1',
                products: products
            })
    }

    static addMaterialToCart(materialNo, currentTab='noTab'){
        let codeOptions = [{
            code: materialNo,
            option: 'buy'
        }]
        this.addToCart(codeOptions, currentTab)
    }
}
