import { Tooltip } from '../../blocks/output-elements/tooltip';
import AddressTypeHelper from '../../myAccount/helper/address-type-helper';
import QuickOrderModal from '../../quickOrder/quickOrderModal/QuickOrderModal.vue';
import { trapFocusToModal } from '@/utils/accessibility';
export default {
  name: 'AddressBox',
  props: ['hasForwardingAgent'],
  data() {
    return {
      activeTab: 'delivery',
      confirmAddressChangeModalId: 'confirmAddressChange'
    };
  },
  components: {
    Tooltip,
    QuickOrderModal
  },
  methods: {
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    getTooltipInfoMessage: function () {
      return AddressTypeHelper.formatAddressInfoMessage([{
        addressType: 'WE'
      }, {
        addressType: 'AG'
      }]);
    },
    openTruckModal() {
      const truckButton = document.querySelector('.cmp--header_truck-modal');
      truckButton.click();
      this.$nextTick(() => {
        const truckModal = document.querySelector('.delivery-truck-modal');
        const truckButtonFocus = document.activeElement;
        if (truckModal) {
          const firstFocusableElement = truckModal.querySelector('button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
          truckModal.addEventListener('keydown', e => {
            trapFocusToModal(e, truckModal);
            if (e.key === 'Escape') {
              truckButtonFocus.focus();
            }
          });
          truckButtonFocus.addEventListener('keydown', e => {
            if (firstFocusableElement && e.key !== 'Escape') {
              firstFocusableElement.focus();
            }
          });
        }
      });
    },
    closeTruckModal() {
      const truckModalCloseButton = document.querySelector('.closeDeliveryTruckModal');
      if (truckModalCloseButton) {
        truckModalCloseButton.dispatchEvent(new Event('click'));
      }
    },
    closeModal(id) {
      $(id).removeClass('visible');
      this.closeTruckModal();
    }
  },
  computed: {
    defaultDeliveryAddress() {
      return this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress;
    },
    sapCustomerId() {
      return this.defaultDeliveryAddress?.sapCustomerID || this.defaultDeliveryAddress?.shipToAddress?.sapCustomerID?.replace(/STD_/, '');
    },
    deliveryName() {
      return this.defaultDeliveryAddress?.internationalAddress?.companyName || this.defaultDeliveryAddress?.name;
    },
    deliveryPostalDetails() {
      const streetName = this.defaultDeliveryAddress?.internationalAddress?.streetName || this.defaultDeliveryAddress?.shipToAddress?.streetName;
      const town = this.defaultDeliveryAddress?.internationalAddress?.town || this.defaultDeliveryAddress?.shipToAddress?.town;
      const region = this.defaultDeliveryAddress?.internationalAddress?.region?.name || this.defaultDeliveryAddress?.shipToAddress?.region?.name;
      const postalCode = this.defaultDeliveryAddress?.internationalAddress?.postalCode || this.defaultDeliveryAddress?.shipToAddress?.postalCode;
      const country = this.defaultDeliveryAddress?.internationalAddress?.country?.name || this.defaultDeliveryAddress?.shipToAddress?.country?.name;
      return `${streetName}\n${town}, ${region}, ${postalCode}\n${country}`;
    },
    companyId() {
      return this.$store.state.user.dccUserInfo?.data?.selectedSoldToId;
    },
    selectedSoldTo() {
      const selectedSoldToId = this.$store.state.user.dccUserInfo?.data?.selectedSoldToId;
      return this.defaultDeliveryAddress?.soldTos.find(soldTo => soldTo.sapCustomerID === selectedSoldToId);
    },
    companyName() {
      return this.selectedSoldTo?.internationalAddress?.companyName || this.selectedSoldTo?.soldToAddress?.companyName;
    },
    companyPostalDetails() {
      const address = this.selectedSoldTo?.internationalAddress?.id ? this.selectedSoldTo?.internationalAddress : this.selectedSoldTo?.soldToAddress;
      const companyStreetName = address.streetName || '';
      const companyTown = address.town || '';
      const companyRegion = address.region?.name || '';
      const companyPostalCode = address.postalCode || '';
      const companyCountry = address.country?.name || '';
      return `${companyStreetName}\n${companyTown}, ${companyRegion}, ${companyPostalCode}\n${companyCountry}`;
    },
    coName() {
      return this.defaultDeliveryAddress?.shipToAddress?.nameCo;
    }
  }
};