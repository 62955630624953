import i18nHelper from '../../../myAccount/helper/i18n-helper'

export const CloseIconRed = {
    template: `<svg role="button" :aria-label="closeButtonLabel" @click="$emit('click', $event)" @keydown.enter="e => { $emit('keydownEnter', e); $emit('click', e); }" class="close-button" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="0.5" stroke="#E80033"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99685 8.70371L10.3733 11.0801L11.0804 10.373L8.70396 7.9966L11.0804 5.62019L10.3733 4.91309L7.99685 7.2895L5.62044 4.91309L4.91333 5.62019L7.28974 7.9966L4.91333 10.373L5.62044 11.0801L7.99685 8.70371Z" fill="#E80033"/>
            </svg>`,
    name: 'CloseIconRed',
    data: function () {
        return {
            closeButtonLabel: i18nHelper.getI18nMessage('button.close.Title')
        }
    }
}
