import { TooltipIcon } from '../../svg-elements/tooltip-icon'
import { isMobile } from '@/utils'

export const Tooltip = {
    template: `
		<span class="tooltip-icon" 
            v-if="tooltipText" 
            @click.prevent="toggleTooltip"
            @keydown.enter.prevent="toggleTooltip" 
            @mouseover="onDisplayTooltip" 
            @mouseout="hideToolTip"
            @keydown.esc.prevent="hideToolTip"
            @focusout="hideToolTip"
            tabindex="0"
            role="button"
            :aria-expanded="showTooltip.toString()"
        >
			<p class="tooltip-text" :class="{ show: showTooltip, 'notranslate': notranslate }" ref="tooltipText" v-html="tooltipText"></p>
			<TooltipIcon ref="tooltipIcon" />
		</span>`,
    data: function () {
        return {
            showTooltip: false
        }
    },
    props: ['passedText', 'notranslate'],
    components: { TooltipIcon },
    computed: {
        tooltipText() {
            return this.passedText ? this.passedText : ''
        }
    },
    methods: {
        toggleTooltip() {
            this.showTooltip = !this.showTooltip
            this.onDisplayTooltip()
            if (isMobile() && this.showTooltip) {
                this.$nextTick(() => {
                    document.addEventListener('click', this.handleClickOutside)
                })
            }
        },
        handleClickOutside(event) {
            const tooltipEl = this.$el
            if (!tooltipEl.contains(event.target)) {
                this.showTooltip = false
                document.removeEventListener('click', this.handleClickOutside)
            }
        },
        onDisplayTooltip: function () {
            const tooltipTextEl = this.$refs.tooltipText
            const iconAnchorPoint = this.getTooltipIconAnchorPoint(
                this.$refs.tooltipIcon.$el
            )

            if (isMobile()) {
                if(iconAnchorPoint.x > document.documentElement.clientWidth / 2)
                {
                    tooltipTextEl.style.right = '-1rem'        
                    tooltipTextEl.style.maxWidth = `${iconAnchorPoint.x}px`
                    tooltipTextEl.style.left = 'auto'
                }
                else{
                    tooltipTextEl.style.left = '0'
                    tooltipTextEl.style.maxWidth = `${document.documentElement.clientWidth - iconAnchorPoint.x}px`
                }
                tooltipTextEl.style.top = '-1rem'  
                tooltipTextEl.style.width = 'max-content' 
                return                 
            }

            const tooltipYAxisOffset = -5
            this.showTooltip = true
            
            const textTargetCoordinates = this.getTooltipTextTargetCoordinates(
                tooltipTextEl,
                iconAnchorPoint
            )

            tooltipTextEl.style.top = `${
                textTargetCoordinates.y + tooltipYAxisOffset
            }px`
            tooltipTextEl.style.left = `${textTargetCoordinates.x}px`
        },
        hideToolTip() {
            if (isMobile()) {
                return
            }

            this.showTooltip = false
        },
        getAbsPosition: function (element) {
            var rect = element.getBoundingClientRect()
            return { x: rect.left, y: rect.top }
        },
        getTooltipIconAnchorPoint: function (element) {
            const coordinates = this.getAbsPosition(element)
            coordinates.x = coordinates.x + element.clientWidth / 2

            return coordinates
        },
        getTooltipTextTargetCoordinates: function (element, iconAnchorPoint) {
            const tooltipTextCoordinates = {
                y: iconAnchorPoint.y - element.clientHeight,
                x: iconAnchorPoint.x - element.clientWidth / 2
            }

            this.adjustViewportOffset(element, tooltipTextCoordinates)

            return tooltipTextCoordinates
        },
        adjustViewportOffset: function (element, elementCoordinates) {
            const viewportSize = {
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth
            }

            if (
                elementCoordinates.x + element.clientWidth >
                viewportSize.width
            ) {
                const viewportOffset =
                    viewportSize.width -
                    (elementCoordinates.x + element.clientWidth)
                elementCoordinates.x = elementCoordinates.x + viewportOffset
            } else if (elementCoordinates.x < 0) {
                elementCoordinates.x = 0
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside)
    }
}
