import CoreComponent from '../../core/CoreComponent.vue';
import { mapState, mapActions } from 'vuex';
import SearchBox from '@/dow-platform/components/commerce/blocks/input-elements/search-box';
import { inject } from 'vue';
import WebsiteUpdatesBanner from '@/dow-platform/components/commerce/blocks/web-updates-banner/WebsiteUpdatesBanner.vue';
const secondSignIn = document.querySelector('.cmp-mega-menu-navigation__nav-secondary')?.querySelector('.signInRegister');
const myAccountButton = document.querySelector('.cmp-mega-menu-navigation__nav-secondary')?.querySelector('.myAccount');
export default {
  extends: CoreComponent,
  components: {
    SearchBox,
    WebsiteUpdatesBanner
  },
  data: () => {
    return {
      links: null,
      navigationDiv: null,
      hamburger: null,
      micrositeHamburger: null,
      myAccountModal: null,
      isButtonHovered: false,
      isModalHovered: false,
      bannerVisible: true,
      languageDropdownVisible: false
    };
  },
  setup() {
    const languageDropDownModal = inject('languageDropDownModal');
    return {
      languageDropDownModal
    };
  },
  computed: {
    userReauthRequired() {
      return this?.$store?.state?.user?.dccUserInfo?.data?.reauthorizationRequired;
    },
    totalCartCount() {
      let numberOfSampleCartItems = this.isAuthenticated ? this.dccUserInfo?.data?.numberOfSampleCartItems : this.anonSampleCartCount;
      return this.dccUserInfo?.data?.numberOfCartItems + numberOfSampleCartItems;
    },
    isBuyingUser() {
      return this.dccUserInfo?.data?.baseRole?.includes('addtocart') ?? false;
    },
    hasSampleAdded() {
      return (this.isAuthenticated ? this.dccUserInfo?.data?.numberOfSampleCartItems : this.anonSampleCartCount) > 0;
    },
    hasCartContent() {
      return this.isBuyingUser || this.hasSampleAdded;
    },
    hasCartItems() {
      return this.totalCartCount > 0;
    },
    ...mapState('user', ['dccUserInfo', 'isAuthenticated', 'anonSampleCartCount'])
  },
  mounted() {
    this.setupDropdown();
    this.links = this.cmpEl.querySelectorAll('.cmp-mega-menu-navigation__nav-buttons-text');
    this.navigationDiv = this.cmpEl.querySelector('.cmp-mega-menu-navigation__nav');
    this.hamburger = this.cmpEl.querySelector('#nav-trigger');
    this.micrositeHamburger = document.querySelector('#micrositeMobileMenuTrigger');
    this.hamburger.addEventListener('click', this.onHamburgerClick);
    this.micrositeHamburger.addEventListener('click', this.onHamburgerClick);
    this.links.forEach((link, index) => {
      link.addEventListener('click', e => {
        e.preventDefault();
        this.toggle(e.target);
        this.navigationDiv.classList.add('cmp-mega-menu-navigation__nav-hidden');
      });
      link.addEventListener('keydown', e => {
        const items = this.links;
        var nextIndex = (index + 1) % items.length;
        var prevIndex = (index - 1 + items.length) % items.length;

        // Up arrow
        if (e.keyCode === 37) {
          items[prevIndex].focus();
          e.preventDefault();
        }
        // Down arrow
        else if (e.keyCode === 39) {
          items[nextIndex].focus();
          e.preventDefault();
        }
      });
    });
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (this.navigationDiv.classList.contains('cmp-mega-menu-navigation__nav-open')) {
          this.resetAll();
        }
      }
    });
    const myAccountSelector = document.querySelector('.cmp-mega-menu-navigation__nav .cmp--header_my-account');
    this.myAccountModal = document.querySelector('.cmp-mega-menu-navigation__nav .cmp--header_my-account-modal');
    if (myAccountSelector) {
      myAccountSelector.addEventListener('click', e => {
        this.myAccountModal.classList.add('cmp--header_my-account-modal--open');
        this.navigationDiv.classList.add('cmp-mega-menu-navigation__nav-hidden');
      });
    }
    const backButtons = document.querySelectorAll('.cmp-mega-menu-navigation__nav .cmp-header__back');
    backButtons.forEach(backButton => {
      backButton.addEventListener('click', e => {
        e.preventDefault();
        if (this.myAccountModal) {
          this.myAccountModal.classList.remove('cmp--header_my-account-modal--open');
        }
        this.navigationDiv.classList.remove('cmp-mega-menu-navigation__nav-hidden');
      });
    });
  },
  methods: {
    onHamburgerClick() {
      this.resetAll();
      if (!this.navigationDiv.classList.contains('cmp-mega-menu-navigation__nav-open')) {
        this.navigationDiv.classList.add('cmp-mega-menu-navigation__nav-open');
        this.hamburger.classList.add('cmp-mega-menu-navigation-hamburger--open');
      } else {
        this.navigationDiv.classList.remove('cmp-mega-menu-navigation__nav-open');
        this.hamburger.classList.remove('cmp-mega-menu-navigation-hamburger--open');
      }
      if (this.hamburger.classList.contains('cmp-mega-menu-navigation-hamburger--open')) {
        document.body.classList.add('scroll-locked');
      } else {
        document.body.classList.remove('scroll-locked');
      }
      this.isAuthenticated === true ? myAccountButton.classList.add('myAccount_active') : secondSignIn.classList.add('signInRegister_active');
    },
    setupDropdown() {
      if (this.cmpEl !== null) {
        let cartButton = document.querySelectorAll('.cart-wrapper');
        cartButton.forEach(item => {
          item.addEventListener('click', e => {
            if (e.target.className === 'cmp--header_cart-modal-dropdown') {
              if (!this.hasCartContent) {
                e.preventDefault();
                window.location.href = this.cartUrl;
              }
            }
          });
          item.addEventListener('mouseenter', e => {
            this.isButtonHovered = true;
            this.isModalHovered = true;
          });
          item.addEventListener('mouseleave', e => {
            this.isModalHovered = false;
            setTimeout(() => {
              if (!this.isModalHovered) {
                this.isButtonHovered = false;
              }
            }, '300');
          });
        });
      }
    },
    resetAll() {
      this.links.forEach(link => {
        const contentId = link.getAttribute('data-target');
        if (contentId) {
          const content = document.getElementById(contentId);
          if (content) {
            content.classList.remove('cmp-mega-menu--active-level-0');
          }
        }
        link.classList.remove('nav-buttons-text--active');
        this.navigationDiv.classList.remove('cmp-mega-menu-navigation__nav-hidden');
        if (this.myAccountModal) {
          this.myAccountModal.classList.remove('cmp--header_my-account-modal--open');
        }
      });
      if (this.hamburger.classList.contains('cmp-mega-menu-navigation-hamburger--open')) {
        this.languageDropDownModal.closeLanguageDropdown();
      }
    },
    toggle(link) {
      const contentId = link.getAttribute('data-target');
      if (contentId) {
        const content = document.getElementById(contentId);
        if (content) {
          if (!content.classList.contains('cmp-mega-menu--active-level-0')) {
            this.resetAll();
            this.hamburger.classList.add('open');
            this.navigationDiv.classList.add('cmp-mega-menu-navigation__nav-open');
            document.body.classList.add('scroll-locked');
            content.classList.add('cmp-mega-menu--active-level-0');
            link.classList.add('nav-buttons-text--active');
          } else {
            this.hamburger.classList.remove('open');
            this.navigationDiv.classList.remove('cmp-mega-menu-navigation__nav-open');
            document.body.classList.remove('scroll-locked');
            content.classList.remove('cmp-mega-menu--active-level-0');
            link.classList.remove('nav-buttons-text--active');
          }
          var firstLink = content.querySelector('.cmp-mega-menu__link');
          if (firstLink) {
            firstLink.focus();
          }
        }
      }
      if (this.navigationDiv.classList.contains("cmp-mega-menu-navigation__nav-open")) {
        this.bannerVisible = false;
      } else {
        let userBannerVisiblePreference = window.sessionStorage.getItem('fetchedWebsiteUpdate');
        if (userBannerVisiblePreference) {
          this.bannerVisible = true;
        }
      }
    },
    ...mapActions('user', ['logoutUser', 'loginRedirect'])
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) {
        myAccountButton.classList.add('myAccount_active');
        secondSignIn.classList.remove('signInRegister_active');
      } else {
        myAccountButton.classList.remove('myAccount_active');
        secondSignIn.classList.add('signInRegister_active');
      }
    },
    languageDropDownModal: {
      handler(newVal, oldVal) {
        this.languageDropdownVisible = newVal.isLanguageDropdownOpen.value;
      },
      deep: true
    }
  }
};