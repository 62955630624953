import axios from 'axios'
import store from '../store'
import ApiClient from '@platform/plugins/AxiosPlugin'
const MAX_MKT_SUB_APP_ITERATIONS = 2000
const CANT_FIND_APP = 'CANT_FIND_APP'
import { AJAX_URL } from './pdp'

export default {
    namespaced: true,
    state: {
        items: {},
        questions: {}
    },
    mutations: {
        updateSelectedMarket: async function (state, payload) {
            state.items[payload.identifier].selectedMarket = state.items[
                payload.identifier
            ].markets.filter(function (market) {
                return market.value === payload.marketCode
            })[0]
            const shouldLoadFallbacks = state?.items[
                payload.identifier
            ]?.submarkets?.filter((item) => {
                return item?.parents?.includes(payload.marketCode) && item.value !== "Other"
            })

            if (shouldLoadFallbacks.length === 0) {
                loadFallbackSubmarkets({
                    parentID: payload.parentID,
                    identifier: payload.identifier,
                    market: payload.marketCode,
                    displayDefaultOptions: true
                }).then(function (submarkets) {
                    if (submarkets && submarkets.length) {
                        submarkets.forEach((submarket) => {
                            const exists = state.items[
                                payload.identifier
                            ].submarkets.find((item) => {
                                return item.value === submarket.value
                            })

                            if (exists && exists.value !== "Other") {
                                const parentExists = exists.parents?.includes(
                                    submarket.value
                                )
                                if (
                                    parentExists.length === 0 &&
                                    submarket.parents.length
                                ) {
                                    submarket.parents.forEach((parent) => {
                                        if (!exists.parents?.includes(parent)) {
                                            exists.parents.push(parent)
                                        }
                                    })
                                    state.items[
                                        payload.identifier
                                    ].submarkets.push(exists)
                                }
                            } else {
                                if(submarket.value !== "Other"){
                                    state.items[payload.identifier].submarkets.push(
                                        submarket
                                    )
                                }
                            }
                        })
                    }
                })
            }

            state.items[payload.identifier].market = payload.marketCode
            state.items[payload.identifier].submarket = null
            state.items[payload.identifier].application = null
            state.items[payload.identifier].selectedSubmarket = {}
            state.items[payload.identifier].selectedApplication = {}
            state.items[payload.identifier].leadScoringValid = false;
        },

        updateSelectedSubmarket: async function (state, payload) {
            state.items[payload.identifier].selectedSubmarket = state.items[
                payload.identifier
            ].submarkets.filter(function (submarket) {
                return submarket.value === payload.submarketCode
            })[0]
            const shouldLoadFallbacks = state.items[
                payload.identifier
            ].applications.filter((item) => {
                return item?.parents?.includes(payload.submarketCode)
            })

            if (shouldLoadFallbacks.length === 0) {
                const fallbackApplications = await loadFallbackApplications({
                    parentID: payload.parentID,
                    identifier: payload.identifier,
                    submarket: payload.submarketCode,
                    displayDefaultOptions: true
                })

                if (fallbackApplications && fallbackApplications.length) {
                    var defaultExists = state.items[
                        payload.identifier
                    ].applications.find((item) => {
                        return item.value === CANT_FIND_APP
                    })
                    if (defaultExists) {
                        var index =
                            state.items[
                                payload.identifier
                            ].applications.indexOf(defaultExists)
                        state.items[payload.identifier].applications.splice(
                            index,
                            1
                        )
                    }

                    fallbackApplications.forEach((application) => {
                        const exists = state.items[
                            payload.identifier
                        ].applications.find((item) => {
                            return item.value === application.value
                        })

                        if (exists && exists.value !== "Other") {
                            const parentExists = exists.parents?.includes(
                                application.value
                            )
                            if (
                                parentExists.length === 0 &&
                                application.parents.length
                            ) {
                                application.parents.forEach((parent) => {
                                    if (!exists.parents?.includes(parent)) {
                                        exists.parents.push(parent)
                                    }
                                })
                                state.items[
                                    payload.identifier
                                ].applications.push(exists)
                            }
                        } else {
                            if(application.value !== "Other"){
                                state.items[payload.identifier].applications.push(
                                    application
                                )
                            }
                        }
                    })

                    var defaultExists = state.items[
                        payload.identifier
                    ].applications.find((item) => {
                        return item.value === CANT_FIND_APP
                    })
                    if (!defaultExists) {
                        state.items[payload.identifier].applications.push({
                            text: Granite.I18n.get('cant.find.application'),
                            value: CANT_FIND_APP
                        })
                    }
                }
            }

            state.items[payload.identifier].submarket = payload.submarketCode
            state.items[payload.identifier].application = null
            state.items[payload.identifier].selectedApplication = {}
            state.items[payload.identifier].leadScoringValid = false;
        },

        updateSelectedApplication: function (state, payload) {
            state.items[payload.identifier].selectedApplication = state.items[
                payload.identifier
            ].applications.filter(function (application) {
                return application.value === payload.applicationCode
            })[0]

            state.items[payload.identifier].application =
                payload.applicationCode
            state.items[payload.identifier].intendedApplication =
                payload.intendedApplication

            if(state.items[payload.identifier].market != null
                               && state.items[payload.identifier].submarket != null
                               && state.items[payload.identifier].application != null){
                if(state.items[payload.identifier].application == CANT_FIND_APP){
                    state.items[payload.identifier].leadScoringValid = state.items[payload.identifier].intendedApplication != null && state.items[payload.identifier].intendedApplication != "";
                }
                else{
                    state.items[payload.identifier].leadScoringValid = true;
                }
            }
        },

        updateIntendedApplication: function (state, payload) {
            state.items[payload.identifier].intendedApplication =
                payload.intendedApplication

            if(state.items[payload.identifier].market != null
                               && state.items[payload.identifier].submarket != null
                               && state.items[payload.identifier].application != null){
                if(state.items[payload.identifier].application == CANT_FIND_APP){
                    state.items[payload.identifier].leadScoringValid = state.items[payload.identifier].intendedApplication != null && state.items[payload.identifier].intendedApplication != "";
                }
                else{
                    state.items[payload.identifier].leadScoringValid = true;
                }
            }
        },
        clearSelectedApplication: function (state, payload) {
            state.items[payload.identifier].application = null;
        }
    },
    getters: {
        getLeadScoringDataForItem: function (state) {
            return function (params) {
                return state.items[params.identifier]
            }
        },
        getLeadScoringQuestions: function (state) {
            return state.questions
        },
        getLeadScoringValid: function (state) {
            return function (params) {
                if(state.items[params.identifier].market != null
                                   && state.items[params.identifier].submarket != null
                                   && state.items[params.identifier].application != null){
                    if(state.items[params.identifier].application == CANT_FIND_APP){
                        state.items[params.identifier].leadScoringValid = state.items[params.identifier].intendedApplication != null && state.items[params.identifier].intendedApplication != "";
                    }
                    else{
                        state.items[params.identifier].leadScoringValid = true;
                    }
                }
                return state.items[params.identifier].leadScoringValid
            }
        }
    },
    actions: {
        loadMarkets: function (context, payload) {
            loadMarkets(context, payload)
        },
        loadSubmarkets: function (context, payload) {
            loadSubmarkets(context, payload)
        },
        loadApplications: function (context, payload) {
            loadApplications(context, payload)
        },
        loadLeadScoring: function (context, payload) {
            loadLeadScoring(context, payload)
        },
        loadProductCategories: function (context, payload) {
            loadProductCategories(context, payload)
        }
    }
}

function loadMarkets({ state }, payload) {
    state.items[payload.identifier] = {
        markets: [],
        submarkets: [],
        applications: [],
        categories: [],
        selectedMarket: {},
        selectedSubmarket: {},
        selectedApplication: {},
        market: payload.marketCode,
        submarket: payload.submarketCode,
        application: payload.applicationCode
    }

    state.items[payload.identifier].markets.push({
        text: Granite.I18n.get('select.one.Title'),
        value: ''
    })
    var markets = []

    return ApiClient.get(AJAX_URL.markets_load)
        .then(function (response) {
            if (response.data) {
                for (
                    var i = 0;
                    i < response.data.length && i <= MAX_MKT_SUB_APP_ITERATIONS;
                    i++
                ) {
                    if(response.data[i].code != "Other"){
                        state.items[payload.identifier].markets.push({
                            text: response.data[i].name,
                            value: response.data[i].code
                        })
                    }
                }

                if (!!payload.marketCode) {
                    state.items[payload.identifier].selectedMarket =
                        state.items[payload.identifier].markets.filter(
                            function (market) {
                                return market.value === payload.marketCode
                            }
                        )[0]
                }
            }
        })
        .catch(function (error) {
            // console.log(error);
        })
}

function loadSubmarkets({ state, dispatch }, params) {
    ApiClient.get(AJAX_URL.industry_submit, {
        params: {
            market: params.market
        }
    })
        .then(function (response) {
            var submarkets = []
            var filteredArray = []
            if (response.data.submarkets) {
                for (
                    var i = 0;
                    i < response.data.submarkets.length &&
                    i <= MAX_MKT_SUB_APP_ITERATIONS;
                    i++
                ) {
                    if(response.data[i].code != "Other"){
                        submarkets.push({
                            text: response.data.submarkets[i].name,
                            value: response.data.submarkets[i].code
                        })
                    }
                }

                filteredArray = submarkets.filter((value) =>
                    state.items[params.identifier].categories?.submarkets
                        ?.map((item) => item.value)
                        ?.includes(value.value)
                )

                if (filteredArray.length) {
                    state.items[params.identifier].submarkets = filteredArray

                    if (filteredArray.length === 1) {
                        state.items[params.identifier].submarket =
                            filteredArray[0].value
                        state.items[params.identifier].selectedSubmarket =
                            filteredArray[0]
                        dispatch('loadApplications', {
                            parentID: params.parentID,
                            identifier: params.identifier,
                            marketCode: params.marketCode,
                            submarketCode: filteredArray[0].value,
                            applicationCode: params.applicationCode,
                            displayDefaultOptions: true
                        })
                    }
                } else {
                    state.items[params.identifier].submarkets = submarkets
                }
                state.items[params.identifier].submarkets.unshift({
                    text: Granite.I18n.get('select.one.Title'),
                    value: ''
                })
            }

            if (!!params.submarketCode) {
                state.items[params.identifier].selectedSubmarket = state.items[
                    params.identifier
                ].submarkets.filter(function (submarket) {
                    return submarket.value === params.submarketCode
                })[0]
            }
        })
        .catch(function (error) {
            // console.log(error);
        })
}

function loadApplications({ state }, params) {
    var params = params
    ApiClient.get(AJAX_url.submarket_submit, {
        params: {
            parentID: params.parentID,
            identifier: params.identifier,
            market: params.marketCode,
            submarket: params.submarketCode,
            application: params.applicationCode,
            displayDefaultOptions: true
        }
    })
        .then(function (response) {
            var applications = []
            var filteredArray = []

            if (response.data.applications) {
                for (
                    var i = 0;
                    i < response.data.applications.length &&
                    i <= MAX_MKT_SUB_APP_ITERATIONS;
                    i++
                ) {
                    if(response.data[i].code != "Other"){
                        applications.push({
                            text: response.data.applications[i].name,
                            value: response.data.applications[i].code
                        })
                    }
                }

                filteredArray = applications.filter((value) =>
                    state.items[params.identifier].categories?.applications
                        ?.map((item) => item.value)
                        ?.includes(value.value)
                )

                if (filteredArray.length) {
                    if (params.displayDefaultOptions) {
                        filteredArray.unshift({
                            text: Granite.I18n.get('select.one.Title'),
                            value: ''
                        })
                        filteredArray.push({
                            text: Granite.I18n.get('cant.find.application'),
                            value: CANT_FIND_APP
                        })
                    }

                    state.items[params.identifier].applications = filteredArray
                } else {
                    state.items[params.identifier].applications = applications
                    if (params.displayDefaultOptions) {
                        applications.unshift({
                            text: Granite.I18n.get('select.one.Title'),
                            value: ''
                        })
                        applications.push({
                            text: Granite.I18n.get('cant.find.application'),
                            value: CANT_FIND_APP
                        })
                    }
                }
            }

            if (!!params.applicationCode) {
                state.items[params.identifier].selectedApplication =
                    state.items[params.identifier].applications.filter(
                        function (application) {
                            return application.value === params.applicationCode
                        }
                    )[0]
            }
        })
        .catch(function (error) {
            // console.log(error);
        })
}

function loadLeadScoring({ state }, params) {
    var params = params

    ApiClient.get(AJAX_URL.leadscore_submit, {
        params: {
            market: params.market,
            submarket: params.submarket
        }
    })
        .then(function (response) {
            if (response.data && response.data.questions) {
                state.questions = response.data
            } else {
                loadGeneralLeadScoring(state)
            }
        })
        .catch(function (error) {
            loadGeneralLeadScoring(state)
        })
}

function loadGeneralLeadScoring(state) {
    var self = this
    ApiClient.get(AJAX_URL.leadscore_general, {
        params: {
            scorecard: 'GENERAL_SCORECARD'
        }
    })
        .then(function (response) {
            if (response.data && response.data.questions) {
                state.questions = response.data
            }
        })
        .catch(function (error) {
             console.log(error);
        })
}

function loadProductCategories({ state }, params) {
    var params = params

    state.items[params.identifier] = {
        markets: [],
        submarkets: [],
        applications: [],
        categories: [],
        selectedMarket: {},
        selectedSubmarket: {},
        selectedApplication: {},
        market: params.marketCode,
        submarket: params.submarketCode,
        application: params.applicationCode,
        tradeProductNumber: params.tradeProductNumber
    }

    return ApiClient.get(AJAX_URL.get_product_categories, {
        params: {
            productNum: params.parentID,
            identifier: params.identifier,
            marketCode: params.marketCode,
            submarketCode: params.submarketCode,
            applicationCode: params.applicationCode
        }
    })
        .then(function (response) {
            let markets = []
            let submarkets = []
            let applications = []

            if (!$.isEmptyObject(response.data)) {
                const categoriesArr = Object.values(response.data)
                markets = getProductCategoriesBy('mkt-', categoriesArr)
                submarkets = getProductCategoriesBy('sub-', categoriesArr)
                applications = getProductCategoriesBy('app-', categoriesArr)

                state.items[params.identifier].markets = markets
                state.items[params.identifier].submarkets = submarkets
                state.items[params.identifier].applications = applications
                state.items[params.identifier].categories = categoriesArr

                if (!!params.marketCode || markets.length == 1) {
                    var lookupMarket = params.marketCode || markets[0].value
                    state.items[params.identifier].selectedMarket =
                        state.items[params.identifier].markets.filter(
                            function (market) {
                                return market.value === lookupMarket
                            }
                        )[0]

                    const shouldLoadFallbacks = state.items[
                        params.identifier
                    ].submarkets.filter((item) => {
                        return item?.parents?.includes(lookupMarket)
                    })

                    if (shouldLoadFallbacks.length === 0 && !params.isCartItem) {
                        loadFallbackSubmarkets({
                            parentID: params.parentID,
                            identifier: params.identifier,
                            market: lookupMarket,
                            displayDefaultOptions: true
                        }).then(function (submarkets) {
                            if (submarkets && submarkets.length) {
                                submarkets.forEach((submarket) => {
                                    const exists = state.items[
                                        params.identifier
                                    ].submarkets.find((item) => {
                                        return (
                                            item.value === submarket.value
                                        )
                                    })

                                    if (exists) {
                                        const parentExists =
                                            exists.parents?.includes(
                                                submarket.value
                                            )
                                        if (
                                            parentExists.length === 0 &&
                                            submarket.parents.length
                                        ) {
                                            submarket.parents.forEach(
                                                (parent) => {
                                                    if (
                                                        !exists.parents?.includes(
                                                            parent
                                                        )
                                                    ) {
                                                        exists.parents.push(
                                                            parent
                                                        )
                                                    }
                                                }
                                            )
                                            state.items[
                                                params.identifier
                                            ].submarkets.push(exists)
                                        }
                                    } else {
                                        state.items[
                                            params.identifier
                                        ].submarkets.push(submarket)
                                    }
                                })

                            }
                        })
                    }
                }

                if (!!params.submarketCode || submarkets.length == 1) {
                    var lookupSubmarket =
                        params.submarketCode || submarkets[0].value
                    state.items[params.identifier].selectedSubmarket =
                        state.items[params.identifier].submarkets.filter(
                            function (submarket) {
                                return (
                                    submarket.value === lookupSubmarket
                                )
                            }
                        )[0]

                    const shouldLoadFallbacks = state.items[
                        params.identifier
                    ].applications.filter((item) => {
                        return item?.parents?.includes(lookupSubmarket)
                    })

                    if (shouldLoadFallbacks.length === 0) {
                        loadFallbackApplications({
                            parentID: params.parentID,
                            identifier: params.identifier,
                            submarket: lookupSubmarket,
                            displayDefaultOptions: true
                        }).then(function (applications) {
                            if (applications && applications.length) {
                                applications.forEach((application) => {
                                    const exists = state.items[
                                        params.identifier
                                    ].applications.find((item) => {
                                        return (
                                            item.value === application.value
                                        )
                                    })

                                    if (exists) {
                                        const parentExists =
                                            exists.parents?.includes(
                                                application.value
                                            )
                                        if (
                                            parentExists.length === 0 &&
                                            application.parents.length
                                        ) {
                                            application.parents.forEach(
                                                (parent) => {
                                                    if (
                                                        !exists.parents?.includes(
                                                            parent
                                                        )
                                                    ) {
                                                        exists.parents.push(
                                                            parent
                                                        )
                                                    }
                                                }
                                            )
                                            state.items[
                                                params.identifier
                                            ].applications.push(exists)
                                        }
                                    } else {
                                        state.items[
                                            params.identifier
                                        ].applications.push(application)
                                    }
                                })

                                if (params.isCartItem) {
                                    if (!!params.applicationCode) {
                                        state.items[params.identifier].selectedApplication = 
                                            state.items[params.identifier].applications.filter(
                                                function (application) {
                                                    return (
                                                        application.value ===
                                                        params.applicationCode
                                                    )
                                                }
                                            )[0]
                                    }

                                    if(state.items[params.identifier].applications.some(application=>application.value==CANT_FIND_APP)) {
                                        state.items[params.identifier].applications = 
                                            state.items[params.identifier].applications.filter(
                                                function (application) {
                                                    return (
                                                        application.value !== CANT_FIND_APP
                                                    )
                                                }
                                            )
                                        state.items[params.identifier].applications.push({
                                            text: Granite.I18n.get('cant.find.application'),
                                            value: CANT_FIND_APP
                                        })
                                    }

                                }

                            }
                        })
                    }
                }

                if (!!params.applicationCode) {
                    state.items[params.identifier].selectedApplication =
                        state.items[params.identifier].applications.filter(
                            function (application) {
                                return (
                                    application.value ===
                                    params.applicationCode
                                )
                            }
                        )[0]
                }
            }

            // If no category data is tagged to the product OR selected categories are not tagged OR is cart item:
            if (
                markets.length === 0 ||
                (params.marketCode &&
                    $.isEmptyObject(
                        state.items[params.identifier].selectedMarket
                    ))
            ) {
                ApiClient.get(AJAX_URL.markets_load)
                    .then(function (response) {
                        if (!$.isEmptyObject(response.data)) {
                            for (
                                var i = 0;
                                i < response.data.length &&
                                i <= MAX_MKT_SUB_APP_ITERATIONS;
                                i++
                            ) {
                                if(response.data[i].code != "Other"){
                                    state.items[params.identifier].markets.push({
                                        text: response.data[i].name,
                                        value: response.data[i].code,
                                        businessAlignment:
                                            response.data[i].businessAlignment
                                    })
                                }

                            }

                            if (!!params.marketCode) {
                                state.items[params.identifier].selectedMarket =
                                    state.items[
                                        params.identifier
                                    ].markets.filter(function (market) {
                                        return (
                                            market.value === params.marketCode
                                        )
                                    })[0]
                            }
                        }
                    })
                    .catch(function (error) {})
            }

            if (
                (submarkets.length === 0 ||
                    $.isEmptyObject(
                        state.items[params.identifier].selectedSubmarket
                    )) &&
                params.marketCode
            ) {
                ApiClient.get(AJAX_URL.industry_submit, {
                    params: {
                        market: params.marketCode
                    }
                })
                    .then(function (response) {
                        if (!$.isEmptyObject(response.data)) {
                            for (
                                var i = 0;
                                i < response.data.submarkets.length &&
                                i <= MAX_MKT_SUB_APP_ITERATIONS;
                                i++
                            ) {
                                if(response.data.submarkets[i].code == "Other") {
                                    if (params.isCartItem) {
                                        response.data.submarkets[i].name = Granite.I18n.get('taxonomy.other')
                                    }else {
                                        continue;
                                    }
                                }
                                state.items[params.identifier].submarkets.push({
                                    text: response.data.submarkets[i].name,
                                    value: response.data.submarkets[i].code,
                                    businessAlignment:
                                        response.data.submarkets[i]
                                            .businessAlignment,
                                    parents: [params.marketCode]
                                })
                            }

                            if (!!params.submarketCode) {
                                state.items[
                                    params.identifier
                                ].selectedSubmarket = state.items[
                                    params.identifier
                                ].submarkets.filter(function (submarket) {
                                    return (
                                        submarket.value === params.submarketCode
                                    )
                                })[0]
                            }
                        }
                    })
                    .catch(function (error) {})
            }

            if (
                (applications.length === 0 ||
                    $.isEmptyObject(
                        state.items[params.identifier].selectedApplication
                    )) &&
                params.submarketCode
            ) {
                ApiClient.get(AJAX_URL.submarket_submit, {
                    params: {
                        submarket: params.submarketCode
                    }
                })
                    .then(function (response) {
                        if (!$.isEmptyObject(response.data)) {
                            for (
                                var i = 0;
                                i < response.data.applications.length &&
                                i <= MAX_MKT_SUB_APP_ITERATIONS;
                                i++
                            ) {
                                if(response.data.applications[i].code != "Other"){
                                    state.items[
                                        params.identifier
                                    ].applications.push({
                                        text: response.data.applications[i].name,
                                        value: response.data.applications[i].code,
                                        businessAlignment:
                                            response.data.applications[i]
                                                .businessAlignment,

                                        parents: [params.submarketCode]
                                    })
                                }
                            }

                            if (!!params.applicationCode) {
                                state.items[
                                    params.identifier
                                ].selectedApplication = state.items[
                                    params.identifier
                                ].applications.filter(function (application) {
                                    return (
                                        application.value ===
                                        params.applicationCode
                                    )
                                })[0]
                            }
                        }
                    })
                    .catch(function (error) {})
            }

            if (markets.length === 1) {
                state.items[params.identifier].market = markets[0].value
                state.items[params.identifier].selectedMarket = markets[0]
            }

            if (
                submarkets.length === 1 &&
                state.items[params.identifier].selectedMarket &&
                state.items[params.identifier].market
            ) {
                state.items[params.identifier].submarket = submarkets[0].value
                state.items[params.identifier].selectedSubmarket = submarkets[0]
            }

            if (
                applications.length === 1 &&
                state.items[params.identifier].selectedSubmarket &&
                state.items[params.identifier].submarket
            ) {
                state.items[params.identifier].application =
                    applications[0].value
                state.items[params.identifier].selectedApplication =
                    applications[0]
            }

            state.items[params.identifier].markets.unshift({
                text: Granite.I18n.get('select.one.Title'),
                value: ''
            })

            if (params.displayDefaultOptions) {
                state.items[params.identifier].submarkets.unshift({
                    text: Granite.I18n.get('select.one.Title'),
                    value: ''
                })
                state.items[params.identifier].applications.unshift({
                    text: Granite.I18n.get('select.one.Title'),
                    value: ''
                })
                state.items[params.identifier].applications.push({
                    text: Granite.I18n.get('cant.find.application'),
                    value: CANT_FIND_APP
                })
            }

            if (
                state.items[params.identifier].application === CANT_FIND_APP
            ) {
                state.items[params.identifier].selectedApplication =
                    state.items[params.identifier].applications.filter(
                        function (application) {
                            return application.value === CANT_FIND_APP
                        }
                    )[0]
                    state.items[params.identifier].intendedApplication =
                    params.intendedApplication ?? null
            }
        })
        .catch(function (error) {
            console.log(error)
        })
}

function loadFallbackSubmarkets(params) {
    return ApiClient.get(AJAX_URL.industry_submit, {
        params: {
            market: params.market
        }
    })
        .then(function (response) {
            var submarkets = []

            if (response.data.submarkets) {
                for (
                    var i = 0;
                    i < response.data.submarkets.length &&
                    i <= MAX_MKT_SUB_APP_ITERATIONS;
                    i++
                ) {
                    submarkets.push({
                        text: response.data.submarkets[i].name,
                        value: response.data.submarkets[i].code,
                        businessAlignment:
                            response.data.submarkets[i].businessAlignment,
                        parents: [params.market]
                    })
                }

                return submarkets
            }
        })
        .catch(function (error) {
            // console.log(error);
        })
}

async function loadFallbackApplications(params) {
    return ApiClient.get(AJAX_URL.submarket_submit, {
        params: {
            submarket: params.submarket
        }
    })
        .then(function (response) {
            var applications = []
            if (response.data.applications) {
                for (
                    var i = 0;
                    i < response.data.applications.length &&
                    i <= MAX_MKT_SUB_APP_ITERATIONS;
                    i++
                ) {
                    applications.push({
                        text: response.data.applications[i].name,
                        businessAlignment:
                            response.data.applications[i].businessAlignment,
                        value: response.data.applications[i].code,
                        parents: [params.submarket]
                    })
                }

                return applications
            }
        })
        .catch(function (error) {
            // console.log(error);
        })
}

function getProductCategoriesBy(prefix, categories) {
    if (
        typeof categories === 'undefined' ||
        categories === null ||
        !Array.isArray(categories) ||
        !prefix
    ) {
        return []
    }

    return categories
        .filter(function (e) {
            return e.categoryData.code.indexOf(prefix) == 0
        })
        .map(function (e) {
            return {
                text: e.categoryData.name,
                value: e.categoryData.code,
                parents: e.parentCodes
            }
        })
}
