import OrderSummary from './../shared/order-summary.vue';
import CartItem from '../shared/cart-item.vue';
import AddressBox from '../shared/address-box.vue';
import CartAlertBox from '../shared/cart-alert-box.vue';
export default {
  name: 'PopulatedCart',
  emits: ["show-remove-item-modal"],
  props: ['cartData', 'apiCallFailure'],
  components: {
    OrderSummary,
    AddressBox,
    CartAlertBox,
    CartItem
  },
  data() {
    return {
      cartEntries: this.cartData?.cartData.entries,
      isMultiPO: this.cartData?.cartData.isMultiPO,
      cartItemLimit: 25,
      isEmptyCartError: false
    };
  },
  watch: {
    cartData: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.cartData && newVal.cartData.entries) {
          this.cartEntries = newVal.cartData.entries;
        }
      }
    }
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    emitRemoveItemModal(item) {
      this.$emit('show-remove-item-modal', {
        item,
        showModal: true
      });
    },
    emptyCartFailure() {
      this.isEmptyCartError = true;
    },
    isCheckoutDisabled() {
      return this.cartData?.cartData?.unConfiguredEntries?.length > 0 || this.cartData?.cartData?.needRemoveEntries?.length > 0 || this.cartData?.cartData?.needEditEntries?.length > 0 || this.cartEntries.length > this.cartItemLimit;
    },
    isUnconfiguredEntry(cartEntry) {
      let unconfiguredEntries = this.cartData?.cartData.unConfiguredEntries;
      return unconfiguredEntries && unconfiguredEntries.find(entry => entry.sapLineItemNumber == cartEntry.sapLineItemNumber);
    },
    isNeedRemoveEntry(cartEntry) {
      let needRemoveEntries = this.cartData?.cartData.needRemoveEntries;
      return needRemoveEntries && needRemoveEntries.find(entry => entry.sapLineItemNumber == cartEntry.sapLineItemNumber);
    },
    isNeedEditEntry(cartEntry) {
      let needEditEntries = this.cartData?.cartData.needEditEntries;
      return needEditEntries && needEditEntries.find(entry => entry.sapLineItemNumber == cartEntry.sapLineItemNumber);
    }
  },
  computed: {
    hasForwardingAgent() {
      return this.cartEntries && this.cartEntries.some(entry => entry.forwardingAgent);
    }
  }
};