import store from '@/store'
import ApiClient from '@/plugins/AxiosPlugin'
export default class EditCartItemHelper {
    static store = store

    static editCartItemSuccessHandler(data) {
        if (data.errorMessage) {
            this.errorMessage = data.errorMessage
            this.error = true
        } else {
            if (data.darwerURL) {
                if (data.materialList) {
                    var materialList = []
                    data.materialList.forEach(function (value) {
                        materialList.push({
                            code: value.code,
                            itemNo: value.itemNo,
                            isMarketOffer: false,
                            forwardingAgent: value.forwardingAgent,
                            isMultiPO: data.isMultiPO
                        })
                    })
                    localStorage.setItem('materialList',JSON.stringify(materialList))
                }
                location.href = data.darwerURL + '?editItem=true'
            }
        }
    }

    static editCartItem(codeOptions) {
        if (codeOptions) {

            ApiClient.get('/dccstorefront/dcc/en/cart/editItemsNew', {
                params: codeOptions
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                EditCartItemHelper.editCartItemSuccessHandler(res.data)
            }).catch(function (res) {
                console.error('Failed to connect to editItemsNew '+res.data)
            })
        }
    }

    static editUnconfiguredCartItems() {

        ApiClient.get('/dccstorefront/dcc/en/cart/editItemsNew', {
            params: {editMultiple:true}
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            EditCartItemHelper.editCartItemSuccessHandler(res.data)
        }).catch(function (res) {
            console.error('Failed to connect to editItemsNew '+res.data)
        })
    }

    static editNeedEditCartItems() {

        ApiClient.get('/dccstorefront/dcc/en/cart/editItemsNew', {
            params: {
                editMultiple:true,
                needEdit:true
            }
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            EditCartItemHelper.editCartItemSuccessHandler(res.data)
        }).catch(function (res) {
            console.error('Failed to connect to editItemsNew '+res.data)
        })
    }
}