export default class QuantityHelper {
    static getFormattedQuantity(value, unit) {
        const number = parseFloat(value)
        const isWhole = number % 1 === 0
        const formatted = number.toLocaleString('en-US', {
            minimumFractionDigits: isWhole ? 0 : 1,
            maximumFractionDigits: 3
        })
        return unit ? `${formatted} ${unit}` : `${formatted}`
    }
}
