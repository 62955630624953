import DcModal from '../../../ui/modal/DcModal.vue';
export default {
  name: 'RemoveItemModal',
  emits: ["show-remove-item-modal", "remove-product"],
  props: {
    titleMessage: {
      type: String,
      required: true
    },
    removeMessage: {
      type: String,
      required: true
    },
    partDescription: {
      type: String,
      required: true
    },
    productNumber: {
      type: String,
      required: true
    }
  },
  components: {
    DcModal
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    closeModal() {
      this.$emit('show-remove-item-modal', {
        showModal: false
      });
    },
    removeProduct() {
      this.$emit('remove-product');
    }
  }
};