export const TooltipIcon = {
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="-3 -2 18 17">
			<g transform="translate(-451 -338)">
				<g transform="translate(451 338)">
					<g class="a">
						<circle class="c" cx="7" cy="7" r="7"/><circle class="d" cx="7" cy="7" r="6.5"/>
					</g>
					<text class="b" transform="translate(7 1)">
						<tspan x="-1.528" y="10">i</tspan>
					</text>
				</g>
			</g>
		</svg>`
}
