import EmptyCart from './empty-cart/empty-cart.vue';
import MarketOffer from './shared/market-offer.vue';
import RemoveItemModal from '../blocks/remove-item-modal/remove-item-modal.vue';
import ServletHelper from '../myAccount/helper/servlet-helper';
import PopulatedCart from './populated-cart/populated-cart.vue';
import ApiClient from '@/plugins/AxiosPlugin';
import LoadingDots from '@/dow-platform/components/commerce/shared/loading-dots/index.js';
import QuickAdd from './shared/quick-add.vue';
export default {
  name: 'PurchaseCart',
  components: {
    MarketOffer,
    EmptyCart,
    RemoveItemModal,
    PopulatedCart,
    LoadingDots,
    QuickAdd
  },
  data() {
    return {
      cartData: null,
      ShowModal: false,
      index: null,
      productDescription: '',
      product: [],
      loading: false,
      apiCallFailure: false,
      productNumber: ''
    };
  },
  async created() {
    this.loading = true;
    try {
      this.cartData = await ServletHelper.makeServletCall(this.$store.state.settings.purchase_cart_servlet, '', {
        'Cache-Control': 'no-cache'
      });
    } catch (error) {
      this.apiCallFailure = true;
      console.error('Error loading cart data:', error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    toggleModal(payload) {
      this.ShowModal = payload.showModal;
      this.productDescription = payload.item?.myPartDescription ?? payload.item?.salesText ?? '';
      this.productNumber = payload.item?.myPartNumber ?? payload.item?.materialNumber ?? '';
      this.product = payload.item;
    },
    removeSingle() {
      this.loading = true;
      ApiClient.get(this.$store.state.settings.remove_cart_servlet, {
        params: {
          materialNumber: this.product.materialNumber,
          sapLineNumber: this.product.sapLineItemNumber
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        this.loading = false;
        this.updateCartCount();
        sessionStorage.setItem('itemRemoved', 'true');
        window.location.reload();
      }.bind(this)).catch(error => {
        this.loading = false;
        this.apiCallFailure = true;
        console.error('Error', error);
      });
    },
    updateCartCount() {
      var purchaseFormData = new FormData();
      purchaseFormData.append('number', -1);
      ApiClient.post('/.dow.purchase.cart.count.json', purchaseFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (this.$store.state.user?.dccUserInfo?.data?.numberOfCartItems > 0) {
        this.$store.state.user.dccUserInfo.data.numberOfCartItems = -1;
      }
    }
  }
};