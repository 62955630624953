import "core-js/modules/es.array.push.js";
import CoreComponent from '../../../core/CoreComponent.vue';
import ArrowIcon from '../../../commerce/blocks/svg-elements/arrow-right-large/ArrowRightLarge.vue';
import RobotIcon from '../../../commerce/blocks/svg-elements/robot-icon/robotIcon.vue';
import SmallArrow from '../../../commerce/blocks/svg-elements/arrow/arrow.vue';
import LoadingDots from '@/dow-platform/components/commerce/blocks/output-elements/loading-dots/LoadingDots.vue';
import { inject } from 'vue';
export default {
  extends: CoreComponent,
  props: ['languageList', 'machineLanguageList', 'mobileAndTabletCheck', 'tagDescription'],
  data() {
    return {
      isChangingLang: false
    };
  },
  components: {
    ArrowIcon,
    SmallArrow,
    RobotIcon
  },
  setup() {
    const languageDropDownModal = inject('languageDropDownModal');
    return {
      languageDropDownModal
    };
  },
  mounted() {
    this.setIsMobileOrTablet();
  },
  unmounted() {},
  methods: {
    getLabel(key) {
      return window.Granite.I18n.get(key);
    },
    getAnchorFromURL(url) {
      var Anchors = url.split('#');
      if (Anchors.length > 1) {
        return '#' + Anchors[1];
      } else {
        return '';
      }
    },
    weGlotLang(languageCode) {
      let language = this.combinedLanguageList[languageCode].title ? this.combinedLanguageList[languageCode].title : this.combinedLanguageList[languageCode].notranslate;
      window.adobeDataLayer.push({
        event: 'weglot:langselect',
        user: {
          language: language
        }
      });
    },
    async switchLanguage(languageCode, path) {
      this.weGlotLang(languageCode);
      this.isChangingLang = true;
      await this.$store.dispatch('browser/setLanguageCode', languageCode);
      if (localStorage.getItem('dowComCoveoToken')) {
        localStorage.removeItem('dowComCoveoToken');
      }
      if (window.location.pathname.includes('/dccstorefront/')) {
        let newPath = window.location.href;
        let urlIndex = newPath.indexOf('/dccstorefront');
        let codeCheck = newPath.slice(urlIndex - 5, urlIndex);
        if (this.machineLanguageKeys.includes(codeCheck)) {
          newPath = newPath.slice(0, urlIndex - 6) + newPath.slice(urlIndex);
          urlIndex = newPath.indexOf('/dccstorefront');
        }
        if (this.languageKeys.includes(languageCode)) {
          window.location.href = newPath;
        } else {
          newPath = newPath.slice(0, urlIndex) + '/' + languageCode + newPath.slice(urlIndex);
          window.location.href = newPath;
        }
      } else {
        let newPath = path + this.getAnchorFromURL(window.location.href);
        if (window.location.pathname.includes('/search.html') && !window.location.href.includes(path)) {
          let urlIndex = newPath.indexOf('&f:');
          newPath = newPath.slice(0, urlIndex);
          window.location.href = newPath;
        } else if (!window.location.href.includes(newPath)) {
          window.location.href = newPath;
        } else {
          window.location.reload();
        }
      }
    },
    toggleLanguageModal() {
      this.languageDropDownModal.toggleLangugageDropdown();
    },
    setIsMobileOrTablet() {},
    handleKeyboardNavigation() {
      const links = document.querySelectorAll('.cmp--header_language-modal .cmp--header_language-modal_option');
      if (links.length) {
        links.forEach((link, index) => {
          link.addEventListener('keydown', function (event) {
            const key = event.key;
            const prev = links[index - 1];
            const next = links[index + 1];
            if (key === 'ArrowUp') {
              event.preventDefault();
              if (prev) {
                prev.focus();
              } else {
                links[links.length - 1].focus();
              }
            }
            if (key === 'ArrowDown') {
              event.preventDefault();
              if (next) {
                next.focus();
              } else {
                links[0].focus();
              }
            }
          });
        });
      }
    }
  },
  computed: {
    currentLang() {
      return this.cmpEmbeddableData.currentLanguage;
    },
    linkURL() {
      return this.cmpEmbeddableData.linkURL;
    },
    languageKeys() {
      return this.languageList != null ? Object.keys(this.languageList) : [];
    },
    machineLanguageKeys() {
      return this.machineLanguageList != null ? Object.keys(this.machineLanguageList) : [];
    },
    combinedLanguageList() {
      const addType = (obj, key, keyValue) => {
        const result = {};
        for (const [itemKey, value] of Object.entries(obj)) {
          result[itemKey] = {
            ...value,
            [key]: keyValue
          };
        }
        return result;
      };
      const addTranslationParts = obj => {
        for (let key in obj) {
          if (obj[key].displayLanguage) {
            // Split the displayLanguage value into two parts
            const [notranslate, translate] = obj[key].displayLanguage.split('-');

            // Add the new entries
            obj[key]['notranslate'] = notranslate;
            obj[key]['translate'] = translate;
          }
        }
      };
      const humanTranslatedLanguageList = addType(this.languageList, 'type', 'human');
      const machineTranslatedLanguageList = addType(this.machineLanguageList, 'type', 'machine');
      const combinedLanguageList = {
        ...humanTranslatedLanguageList,
        ...machineTranslatedLanguageList
      };
      addTranslationParts(combinedLanguageList);
      return combinedLanguageList;
    },
    tagDescription() {
      return this.tagDescription;
    },
    combinedLanguageKeys() {
      const combinedLanguageList = {
        ...this.languageList,
        ...this.machineLanguageList
      };
      return combinedLanguageList != null ? Object.keys(combinedLanguageList) : [];
    }
  }
};