import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "remove-modal-title",
  "aria-label": "Remove Item Modal Title"
};
const _hoisted_2 = {
  class: "remove-modal-text",
  "aria-label": "Remove Item Modal Text"
};
const _hoisted_3 = {
  class: "remove-modal-part-name",
  "aria-label": "Remove Item Modal Part Name"
};
const _hoisted_4 = {
  class: "remove-modal-part-description"
};
const _hoisted_5 = {
  class: "remove-modal-part-number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DcModal = _resolveComponent("DcModal");
  return _openBlock(), _createBlock(_component_DcModal, {
    onModalClose: _cache[2] || (_cache[2] = $event => $options.closeModal()),
    "aria-label": "Remove Item Modal"
  }, {
    header: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.titleMessage), 1), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString($props.removeMessage), 1)])])]),
    body: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_4, _toDisplayString($props.partDescription), 1), _createElementVNode("p", _hoisted_5, _toDisplayString($props.productNumber), 1)])])]),
    footer: _withCtx(() => [_createElementVNode("button", {
      class: "remove-modal-button-no",
      onClick: _cache[0] || (_cache[0] = $event => $options.closeModal()),
      "aria-label": "No Button"
    }, _toDisplayString($options.getI18nMessage('button.no.UPPER')), 1), _createElementVNode("button", {
      class: "remove-modal-button-yes",
      type: "submit",
      onClick: _cache[1] || (_cache[1] = $event => $options.removeProduct()),
      "aria-label": "Yes Button"
    }, _toDisplayString($options.getI18nMessage('button.yes.UPPER')), 1)]),
    _: 1
  });
}