import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "purchase-cart-blank"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "purchase-cart-heading"
};
const _hoisted_4 = {
  class: "purchase-cart-heading-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoadingDots = _resolveComponent("LoadingDots");
  const _component_QuickAdd = _resolveComponent("QuickAdd");
  const _component_MarketOffer = _resolveComponent("MarketOffer");
  const _component_PopulatedCart = _resolveComponent("PopulatedCart");
  const _component_EmptyCart = _resolveComponent("EmptyCart");
  const _component_RemoveItemModal = _resolveComponent("RemoveItemModal");
  return _openBlock(), _createBlock(_Teleport, {
    to: "#purchaseCart"
  }, [_createVNode(_component_LoadingDots, {
    loading: $data.loading
  }, null, 8, ["loading"]), _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [[_vShow, $data.loading]]), !$data.loading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h2", _hoisted_4, _toDisplayString($options.getI18nMessage('cart.page.name')), 1), _createVNode(_component_QuickAdd)]), this.cartData?.displayMarketOfferLink ? (_openBlock(), _createBlock(_component_MarketOffer, {
    key: 0
  })) : _createCommentVNode("", true), this.cartData?.cartData?.totalItems || $data.apiCallFailure ? (_openBlock(), _createBlock(_component_PopulatedCart, {
    key: 1,
    apiCallFailure: $data.apiCallFailure,
    onShowRemoveItemModal: $options.toggleModal,
    cartData: $data.cartData
  }, null, 8, ["apiCallFailure", "onShowRemoveItemModal", "cartData"])) : (_openBlock(), _createBlock(_component_EmptyCart, {
    key: 2
  }))])])) : _createCommentVNode("", true), $data.ShowModal ? (_openBlock(), _createBlock(_component_RemoveItemModal, {
    key: 1,
    productNumber: $data.productNumber,
    partDescription: $data.productDescription,
    removeMessage: $options.getI18nMessage('remove.item.confirm.sure'),
    titleMessage: $options.getI18nMessage('remove.item.Title'),
    onRemoveProduct: $options.removeSingle,
    onShowRemoveItemModal: $options.toggleModal
  }, null, 8, ["productNumber", "partDescription", "removeMessage", "titleMessage", "onRemoveProduct", "onShowRemoveItemModal"])) : _createCommentVNode("", true)]);
}